export const isJson = (input: string | undefined): boolean => {
  if (!input) {
    return false
  }
  try {
    JSON.parse(input)
    return true
  } catch (e) {
    return false
  }
}
