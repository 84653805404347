<script lang="ts">
import type { DealModel } from '@/capability/deal/types'
import type { ProgramModel } from '@/capability/program/types'

export interface Props {
  deal: DealModel
  program: ProgramModel
}
</script>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import sortBy from 'lodash.sortby'

import { currencyFormatter, formatDateForInput } from '@/lib/utils/formatting'
import { useNotificationStore } from '@/stores/notification'

import { billingInvoicingService } from '@/capability/billing-invoicing/service'
import { stateItems } from '@/capability/deal/utils'
import { lineOfBusinessService } from '@/capability/line-of-business/service'
import type { LineOfBusinessModel } from '@/capability/line-of-business/types'
import type { CalculateTaxesResponse, SurplusLineDtoTransactionTypeEnum } from 'typescript-core-api-client'

import { Checkbox } from '@/component/arqu-components/shadcn/ui/checkbox'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/component/arqu-components/shadcn/ui/dialog'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = defineProps<Props>()

const notificationStore = useNotificationStore()
const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

const transactionTypes = [
  {
    id: 'PN',
    name: 'New Policy'
  },
  {
    id: 'PR',
    name: 'Renewal Policy'
  },
  {
    id: 'APE',
    name: 'Additional Premium Endorsement'
  },
  {
    id: 'RPE',
    name: 'Return Premium Endorsement'
  },
  {
    id: 'AE',
    name: 'Audit Endorsement'
  },
  {
    id: 'FC',
    name: 'Flat Cancellation'
  },
  {
    id: 'ZPE',
    name: 'Zero Premium Endorsement'
  },
  {
    id: 'PC',
    name: 'Pro Rata Cancellation'
  },
  {
    id: 'XE',
    name: 'Extension Endorsement'
  },
  {
    id: 'ARE',
    name: 'Audit Return Premium Endorsement'
  },
  {
    id: 'IE',
    name: 'Reinstatement'
  },
  {
    id: 'BD',
    name: 'Binder'
  }
]

const accountWrittenAs = [
  {
    id: 'B',
    name: 'Brokerage'
  },
  {
    id: 'DC',
    name: 'Direct'
  }
]

// create a context with all the fields
type ContextType = {
  physicalState: string | undefined
  lineOfBusiness: string
  transactionType: (typeof transactionTypes)[number]['id']
  premium: number
  agencyFee: number
  inspectionFee: number
  riskPurchasingGroup: boolean
  policyEffectiveDate: string | undefined
  accountWrittenAs: (typeof accountWrittenAs)[number]['id']
  commissionReceived: boolean
  exemptCommissionPurchaser: boolean
}
const context = ref<ContextType>({
  physicalState: props.program.surplusLine?.physicalState,
  lineOfBusiness: props.program.surplusLine?.lineOfBusinessId ?? '',
  transactionType: getTransactionTypeCode(props.program.surplusLine?.transactionType),
  premium: 0,
  agencyFee: 0,
  inspectionFee: 0,
  riskPurchasingGroup: false,
  policyEffectiveDate: props.deal.policyInfo?.effectiveStartDate,
  accountWrittenAs: 'B',
  commissionReceived: true,
  exemptCommissionPurchaser: false
})

const calculateTaxResponse = ref<CalculateTaxesResponse>()

const lineOfBusinesses = ref<LineOfBusinessModel[]>([])

function getTransactionTypeCode(transactionType?: SurplusLineDtoTransactionTypeEnum) {
  switch (transactionType) {
    case 'NewPolicy':
      return 'PN'
    case 'RenewalPolicy':
      return 'PR'
    default:
      return ''
  }
}

async function handleSubmit() {
  try {
    loading.value = true
    calculateTaxResponse.value = await billingInvoicingService.calculateTaxesAndFeesByRequest({
      request: {
        account_written_as: context.value.accountWrittenAs,
        agency_fee: context.value.agencyFee,
        commission_received: context.value.commissionReceived,
        ecp: context.value.exemptCommissionPurchaser,
        inspection_fee: context.value.inspectionFee,
        line_of_business: context.value.lineOfBusiness,
        physical_address: '',
        physical_city: '',
        physical_state: context.value.physicalState,
        physical_zip_code: '',
        policy_effective_date: formatDateForInput(new Date(context.value.policyEffectiveDate as string)),
        premium: context.value.premium,
        rpg: context.value.riskPurchasingGroup,
        transaction_type: context.value.transactionType
      }
    })
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  } finally {
    loading.value = false
  }
}

const getTotalAmount = computed(() => {
  return (
    (context.value?.premium ?? 0) +
    (context.value?.agencyFee ?? 0) +
    (context.value?.inspectionFee ?? 0) +
    (calculateTaxResponse.value?.sl_tax ?? 0) +
    (calculateTaxResponse.value?.stamping_fee ?? 0) +
    (calculateTaxResponse.value?.sl_service_charge ?? 0) +
    (calculateTaxResponse.value?.municipal_fee ?? 0)
  )
})

const getSLTaxPercentage = computed(() => {
  return (((calculateTaxResponse?.value?.sl_tax || 0) / context.value.premium) * 100).toFixed(2)
})

const getStampingFeePercentage = computed(() => {
  return (((calculateTaxResponse?.value?.stamping_fee || 0) / context.value.premium) * 100).toFixed(2)
})

function handleClose() {
  dialog.value = false
}

const isSubmitBtnDisabled = computed(() => {
  return !context.value.premium || !context.value.physicalState
})

watch(
  context,
  () => {
    calculateTaxResponse.value = undefined
  },
  { deep: true }
)

onMounted(() => {
  lineOfBusinessService
    .getAll()
    .then((res) => {
      lineOfBusinesses.value = sortBy(res, 'name')
    })
    .catch((err) => {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    })
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <TooltipProvider :delay-duration="100">
      <Tooltip>
        <TooltipTrigger>
          <DialogTrigger as-child>
            <slot name="trigger">
              <rq-btn id="programs-navbar-tax-calculator-button" icon="square" size="lg" variant="ghost-primary">
                <rq-icon icon="lucide:calculator" />
              </rq-btn>
            </slot>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Tax Calculator</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <DialogContent
      class="w-[95vw] max-w-[100vw] sm:max-w-[85vw] lg:max-w-[75vw] xl:max-w-[65vw]"
      @escape-key-down.prevent=""
      @interact-outside.prevent=""
      @pointer-down-outside.prevent=""
    >
      <DialogHeader>
        <DialogTitle>Tax Calculator</DialogTitle>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div class="space-y-3">
          <!-- Group 1: Physical State, Transaction Type, Line of Business, Policy Effective Date, risk purchasing group -->
          <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
            <rq-listbox-single
              v-model="context.physicalState"
              id="physicalState"
              :items="stateItems"
              label="Physical State"
              wrapper-class="w-full"
            />
            <rq-listbox-single
              v-model="context.transactionType"
              id="transactionType"
              :items="transactionTypes"
              label="Transaction Type"
              text-field="name"
              truncate-length="48"
              value-field="id"
              wrapper-class="w-full"
            />
            <rq-listbox-single
              v-model="context.lineOfBusiness"
              id="lineOfBusiness"
              :items="lineOfBusinesses"
              label="Line of Business"
              text-field="name"
              truncate-length="48"
              value-field="id"
              wrapper-class="w-full"
            />
            <rq-date-input-component
              v-model="context.policyEffectiveDate"
              id="policyEffectiveDate"
              class="w-full"
              label="Policy Effective Date"
            />
            <div class="flex items-center space-x-2">
              <Checkbox v-model:checked="context.riskPurchasingGroup" id="riskPurchasingGroup" class="border-gray-500" />
              <Label for="riskPurchasingGroup">Risk Purchasing Group</Label>
            </div>
          </div>

          <!-- Group 2: Premium, Agency Fee, Inspection Fee -->
          <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
            <rq-text-field
              v-model="context.premium"
              id="premium"
              class="w-full"
              label="Premium"
              min="0"
              prepend-icon="lucide:dollar-sign"
              step=".01"
              type="number"
            />

            <rq-text-field
              v-model="context.agencyFee"
              id="agencyFee"
              class="w-full"
              label="Broker Fee (Agency Fee)"
              min="0"
              prepend-icon="lucide:dollar-sign"
              step=".01"
              type="number"
            />

            <rq-text-field
              v-model="context.inspectionFee"
              id="inspectionFee"
              class="w-full"
              label="Carrier Fee (Inspection Fee)"
              min="0"
              prepend-icon="lucide:dollar-sign"
              step=".01"
              type="number"
            />
          </div>
        </div>
        <DialogFooter class="pt-2">
          <rq-btn type="button" variant="outline" @click="handleClose">Close</rq-btn>
          <rq-btn :disabled="isSubmitBtnDisabled" :loading="loading" type="submit" variant="primary">Submit</rq-btn>
        </DialogFooter>
      </form>
      <!-- Summary Results -->
      <div v-if="calculateTaxResponse" class="border-t border-gray-200 p-4">
        <h3 class="mb-4 text-lg font-medium">Summary</h3>
        <ul class="grid grid-cols-1 gap-4">
          <li class="flex justify-between">
            <span>Premium:</span>
            <span>{{ currencyFormatter().format(context.premium) }}</span>
          </li>
          <li class="flex justify-between">
            <span>Broker Fee (Agency Fee):</span>
            <span>{{ currencyFormatter().format(context.agencyFee) }}</span>
          </li>
          <li class="flex justify-between">
            <span>Carrier Fee (Inspection Fee):</span>
            <span>{{ currencyFormatter().format(context.inspectionFee) }}</span>
          </li>
          <li class="flex justify-between">
            <span class="flex space-x-2">
              <span>Surplus Line Tax:</span>
              <span>{{ getSLTaxPercentage }}%</span>
            </span>
            <span>{{ currencyFormatter().format(calculateTaxResponse.sl_tax ?? 0) }}</span>
          </li>
          <li class="flex justify-between">
            <span class="flex space-x-2">
              <span>Stamping Fee:</span>
              <span>{{ getStampingFeePercentage ?? 0 }}%</span>
            </span>
            <span>{{ currencyFormatter().format(calculateTaxResponse.stamping_fee ?? 0) }}</span>
          </li>
          <li class="flex justify-between font-bold">
            <span>Total Cost to Insured:</span>
            <span>{{ currencyFormatter().format(getTotalAmount) }}</span>
          </li>
        </ul>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
