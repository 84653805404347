<script setup lang="ts">
import type { PropType } from 'vue'
import { useRoute } from 'vue-router'

import { cn } from '@/lib/utils'

import type { ProgramModel } from '@/capability/program/types'

import { getLinks, type LinkType } from './program-page-navigation'

const route = useRoute()

defineProps({
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  }
})

const dealId = route.params.dealId as string
const programId = route.params.programId as string

const links = getLinks({ dealId, programId })

const isCurrentRoute = (link: LinkType): boolean => {
  if (link.to.name === route.name) {
    return true
  } else if (link.to.name === 'ProgramStructure' && route.name === 'ProgramComponentBase') {
    return true
  } else if (route.name === 'programMarketDetailed' && link.to.name === route.meta.priorRouteLocation) {
    return true
  } else if (route.query.view && route.query.view === link.to.name) {
    return true
  }
  return false
}
</script>

<template>
  <div class="flex space-x-2">
    <rq-tooltip-btn
      v-for="link in links"
      :id="link.name"
      :key="link.icon"
      as-child
      :class="cn(isCurrentRoute(link) ? 'bg-info' : 'unselected-link', 'text-none mx-1 my-auto rounded-lg px-3 py-1')"
      :content="link.tooltipText"
      :datacy="`Button-${link.name}`"
      :disabled="link.unavailable"
      icon="square"
      size="lg"
    >
      <router-link v-permission="[link.name, programModel]" :to="link.to">
        <rq-icon :class="isCurrentRoute(link) ? 'text-white' : 'text-info'" :icon="link.icon" />
      </router-link>
    </rq-tooltip-btn>
  </div>
</template>

<style scoped lang="scss">
.unselected-link {
  background-color: hsla(0, 0%, 97%);
  &:hover {
    background-color: hsla(0, 0%, 90%);
  }
}
</style>
