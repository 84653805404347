<script lang="ts">
import type { TowerModel } from '@/capability/tower/types'

export type Props = {
  defaultLimit: number
}
</script>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { DialogClose } from 'radix-vue'
import { z } from 'zod'

import { useTowerStore } from '@/stores/towers'

import type { LayerModel } from '@/capability/layer/types'
import { createLayer, getLayerExcess, getLayerLimit, setLayerLimit } from '@/capability/layer/utils'
import {
  assignOrderToUnorderedTowers,
  createTower,
  getLayersTotal,
  getNextTowerOrder,
  getTowerLimit,
  setUpTopPlugLayer
} from '@/capability/tower/utils'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import TowerInputGroup from '@/component/tower/TowerInputGroup.vue'

const props = withDefaults(defineProps<Props>(), {
  defaultLimit: 100
})

const towers = defineModel<TowerModel[]>('towers', { default: [] })

const emit = defineEmits<{
  (e: 'open', payload: boolean): void
}>()

const towerStore = useTowerStore()

// Declarations
const dialog = ref<boolean>(false)
const valid = ref<boolean>(true)
const localTowers = ref<TowerModel[]>([])

const newTowers = ref<string[]>([])

const towerText = computed(() => (towers.value.length ? 'Add / Edit Towers' : 'Add Towers'))
const towerNeedsToBeUpdated = ref<{ [key: string]: boolean }>({})
const isNewTower = (id: string): boolean => newTowers.value.includes(id)

watch(dialog, (value) => {
  if (value) {
    localTowers.value = towers.value
    localTowers.value.forEach((tower) => {
      towerNeedsToBeUpdated.value[tower.id!] = false
    })
  } else {
    localTowers.value = []
    towerNeedsToBeUpdated.value = {}
  }
})

// Methods
function handleRemove(id: string): void {
  localTowers.value = localTowers.value.filter((e: TowerModel) => e.id !== id) ?? []
  newTowers.value = newTowers.value.filter((e) => e !== id)
}

function handleUpdate(tower: TowerModel, removeNewFlag?: boolean, toBeUpdated?: boolean): void {
  if (removeNewFlag) {
    newTowers.value = newTowers.value.filter((e) => e !== tower.id)
  }
  if (toBeUpdated !== undefined) {
    if (!newTowers.value.includes(tower.id!)) {
      towerNeedsToBeUpdated.value[tower.id!] = toBeUpdated
    }
  }
  localTowers.value = localTowers.value.map((e) => {
    if (tower.id !== e.id) return e
    const towerClone = cloneDeep(tower)
    if (towerClone.id && newTowers.value.includes(towerClone.id)) {
      // If this is a new tower then any update to
      // the limit should also change the limit of
      // the layer within
      const layer = cloneDeep(towerClone.layers![0])
      setLayerLimit(layer, getTowerLimit(towerClone)!)
      towerClone.layers = [layer]
    }
    return towerClone
  })
  validateForm()
}

const submitDisabled = computed(
  () =>
    localTowers.value.some((tower) =>
      tower.layers
        ?.filter((layer) => !layer.plug)
        .some((layer) => (getLayerExcess(layer) ?? 0) + (getLayerLimit(layer) ?? 0) > (getTowerLimit(tower) ?? 0))
    ) || Object.values(towerNeedsToBeUpdated.value).some((e) => e)
)

function handleAdd(): void {
  const tower: TowerModel = createTower({ limit: props.defaultLimit })
  tower.displayOrder = getNextTowerOrder(localTowers.value)
  newTowers.value = [...newTowers.value, tower.id!]
  const layer = createLayer({ excess: 0, limit: getTowerLimit(tower)! })
  tower.layers = [layer]
  localTowers.value = [...localTowers.value, tower]
}

async function handleSubmit(): Promise<void> {
  validateForm()
  if (valid.value) {
    towers.value = assignOrderToUnorderedTowers(localTowers.value.map((e) => setUpTopPlugLayer(e)))
    await nextTick()
    dialog.value = false
    newTowers.value = []
    if (localTowers.value.length > 0) {
      // scroll to the tower that was just added
      const layerId = localTowers.value[localTowers.value.length - 1].layers![0].id
      const element = document.querySelector(`#layer-target-edit-${layerId}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (towerStore) {
        towerStore.$patch({
          activeTowerId: localTowers.value[localTowers.value.length - 1].id,
          activeLayerId: layerId || ''
        })
      }
    }
  }
}

function getZodObject(id: string) {
  const _tower = localTowers.value.find((e) => e.id === id)
  const tower = cloneDeep(_tower)
  const minVal = Math.max(1, getLayersTotal(tower?.layers as LayerModel[]))
  const object = {
    name: z.string().min(1, 'Name is required'),
    coverage: z.object({
      excess: z.object({
        amount: z.number().int().min(0, 'The minimum value is 0')
      }),
      limit: z.object({
        amount: z.number().int().min(minVal, `The minimum value is ${minVal}`)
      })
    })
  }
  return object
}

type ErrorType = {
  [key: string]: { name: string[]; limit: string[]; excess: string[] }
}

const errors = ref<ErrorType>({} as ErrorType)
function resetErrors() {
  Object.keys(errors.value).forEach((key) => {
    errors.value[key] = { name: [], limit: [], excess: [] }
  })
}
function validateForm() {
  let _valid = true
  resetErrors()
  for (const _tower of localTowers.value) {
    const tower = cloneDeep(_tower)
    const schema = z.object(getZodObject(tower.id as string))
    const result = schema.safeParse(tower)
    errors.value[tower.id!] = { name: [], limit: [], excess: [] }
    if (!result.success) {
      for (const issue of result.error.issues) {
        if (issue.path[0] === 'name') {
          errors.value[tower.id!].name.push(issue.message)
        } else if (issue.path[0] === 'coverage') {
          if (issue.path[1] === 'excess' && issue.path[2] === 'amount') {
            errors.value[tower.id!].excess.push(issue.message)
          } else if (issue.path[1] === 'limit' && issue.path[2] === 'amount') {
            errors.value[tower.id!].limit.push(issue.message)
          }
        }
      }
      _valid = false
    }
  }
  valid.value = _valid
}
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <div class="mt-2 flex justify-center">
        <rq-btn
          id="add-tower-activator-button"
          class="flex items-center space-x-2"
          datacy="addEditTowerButton"
          variant="primary"
          v-bind="$attrs"
        >
          <rq-icon icon="lucide:circle-plus" />
          <span>{{ towerText }}</span>
        </rq-btn>
      </div>
    </DialogTrigger>
    <DialogContent class="mx-auto max-h-[750px] w-[500px]">
      <template #close>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <DialogClose class="absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-gray-100" datacy="dialog-close">
                <rq-icon icon="lucide:x" />
                <span class="sr-only">Close</span>
              </DialogClose>
            </TooltipTrigger>
            <TooltipContent>This will undo any new changes!</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </template>
      <DialogHeader>
        <DialogTitle>Modify Tower Structure</DialogTitle>
        <DialogDescription>Add/update layers and segments here</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div class="max-h-[500px] overflow-y-auto px-1">
          <TowerInputGroup
            v-for="tower in localTowers"
            :key="tower.id"
            :errors="errors[tower.id!]"
            :is-new-tower="isNewTower(tower.id!)"
            :to-be-updated="towerNeedsToBeUpdated[tower.id!] ?? false"
            :tower="tower"
            @keydown:submit="handleSubmit"
            @remove:tower="handleRemove"
            @update:tower="handleUpdate"
          />
        </div>
        <rq-btn
          class="my-2 flex w-full items-center space-x-2 text-xs font-bold normal-case"
          datacy="addTowerModifyStructureModal"
          type="button"
          variant="primary-outline"
          @click="handleAdd"
        >
          <rq-icon icon="lucide:circle-plus" />
          <span>Add Tower</span>
        </rq-btn>
        <DialogFooter>
          <rq-btn type="button" variant="outline" @click="dialog = false">Cancel</rq-btn>
          <rq-btn id="tower-modal-done" datacy="applyTowerButton" :disabled="submitDisabled" type="submit" variant="primary">Apply</rq-btn>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
