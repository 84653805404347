<script lang="ts">
import type { DealModel } from '@/capability/deal/types'
import type { ExposureModel } from '@/capability/exposure/types'
import type { ProgramModel } from '@/capability/program/types'

export interface Props {
  deal: DealModel
  programModel: ProgramModel
}
</script>

<script setup lang="ts">
import { useAcl } from 'vue-simple-acl/src'

import { useNotificationStore } from '@/stores/notification'

import { exportAsExcel } from '@/capability/program/utils'

import AddExposureDialog from '@/component/exposure/AddExposureDialog.vue'
import ProgramPublishToRetailer from '@/component/program/program-navbar/ProgramPublishToRetailer.vue'

const props = defineProps<Props>()

const acl = useAcl()
const notificationStore = useNotificationStore()

const exposures = defineModel<ExposureModel[]>('exposuresModel', { default: () => [] })

async function handleExportAsExcel() {
  try {
    await exportAsExcel({ deal: props.deal, program: props.programModel })
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  }
}

// const exportAsPdf = async () => {
//   try {
//     const response = await programService.exportAsPdf(props.programModel)
//     const blob = response.data
//     const reader = new FileReader()
//     reader.readAsDataURL(blob)
//     reader.onload = (e: ProgressEvent<FileReader>) => {
//       const a = document.createElement('a')
//       const dateStr = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.now())
//       a.download = props.deal.clientInfo?.name + '-' + props.programModel.title + '-' + dateStr + '.pdf'
//       a.href = e.target?.result as string
//       document.body.appendChild(a)
//       a.click()
//       document.body.removeChild(a)
//     }
//   } catch (e) {
//     notificationStore.publishOneOrMoreErrUnhandled(e)
//   }
// }
</script>

<template>
  <div class="flex items-center space-x-1 pl-2">
    <AddExposureDialog v-if="acl.anyRole(['admin', 'rs'])" v-model:exposures="exposures" :program="programModel" />

    <ProgramPublishToRetailer
      v-if="acl.anyRole(['admin', 'rs'])"
      :deal="deal"
      :organization-id="deal.retailerInfo?.organizationId ?? ''"
      :program-name="programModel.title as string"
    />
    <rq-tooltip-btn
      v-if="acl.anyRole(['admin', 'rs', 'retailer'])"
      id="programs-navbar-export-as-excel-button"
      class="bg-pink-400 hover:bg-pink-500"
      content="Export as Excel"
      datacy="export-to-excel"
      :delay-duration="100"
      icon="square"
      size="lg"
      variant="ghost"
      @click="handleExportAsExcel"
    >
      <rq-icon color="white" icon="lucide:file-spreadsheet" />
    </rq-tooltip-btn>
  </div>
</template>

<style scoped lang="scss"></style>
