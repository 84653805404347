import type { TowerModel } from '@/capability/tower/types'
import type { ProgramDto, ProgramTemplateListDto } from 'typescript-core-api-client'

export interface ProgramModel extends Omit<ProgramDto, 'towers'> {
  towers?: TowerModel[]
}

export interface ProgramMinimalModel extends ProgramDto {}

export interface ProgramTemplateListModel extends ProgramTemplateListDto {}

// The list of program view types
export enum ProgramView {
  UNKNOWN = 'UNKNOWN',
  STRUCTURE = 'STRUCTURE',
  INDICATION_REQUEST = 'INDICATION_REQUEST',
  BIND_SCENARIO = 'BIND_SCENARIO',
  REQUEST_FORMAL_QUOTE = 'REQUEST_FORMAL_QUOTE',
  ISSUE_BIND_OFFER = 'ISSUE_BIND_OFFER'
}

export type ProgramSections = 'limits' | 'deductibles' | 'form'

export type LineItemNavigationItemType = {
  name: string
  icon: string
  location: ProgramSections
}

export type ProgramIndicationAccessType = {
  id: string
  type: string
  name: string
}
