import { type LineItemModel } from '@/capability/line-item/types'
import { apiConfiguration } from '@/capability/system/config'
import {
  type CreateLineItemRequest,
  type CreateLineItemResponse,
  LineItemApi,
  type UpdateLineItemsRequest,
  type UpdateLineItemsResponse
} from 'typescript-core-api-client'
import {
  type ApplyCarrierLineItemTemplateRequest,
  type ApplyTowerLineItemTemplateRequest,
  type CreateCarrierLineItemTemplateRequest,
  type CreateTowerLineItemTemplateRequest,
  IndicationApi,
  ProgramApi,
  type ProgramDto
} from 'typescript-core-api-client'
import { type LineItemTemplateDto } from 'typescript-core-api-client/dist/api'

type StatusType = 'Active' | 'Disabled' | 'Initial' | 'Unknown' | 'Unverified'

export type GetAllLineItemsPayloadType = {
  status: StatusType[]
}

export type DeleteTowerLineItemTemplatePayloadType = {
  lineItemTemplateId: string
}

export interface LineItemService {
  getAllLineItems: (payload: GetAllLineItemsPayloadType) => Promise<LineItemModel[]>
  createLineItem: (request: CreateLineItemRequest) => Promise<CreateLineItemResponse>
  updateLineItems: (request: UpdateLineItemsRequest) => Promise<UpdateLineItemsResponse>
  listTowerLineItemTemplates: () => Promise<LineItemTemplateDto[]>
  createTowerLineItemTemplate: (payload: CreateTowerLineItemTemplateRequest) => Promise<void>
  applyTowerLineItemTemplate: (payload: ApplyTowerLineItemTemplateRequest) => Promise<ProgramDto>
  listCarrierLineItemTemplates: () => Promise<LineItemTemplateDto[]>
  createCarrierLineItemTemplate: (payload: CreateCarrierLineItemTemplateRequest) => Promise<void>
  applyCarrierLineItemTemplate: (payload: ApplyCarrierLineItemTemplateRequest) => Promise<ProgramDto>
  deleteTowerLineItemTemplate: (payload: DeleteTowerLineItemTemplatePayloadType) => Promise<void>
}

export const lineItemService = {
  getAllLineItems: async function (payload: GetAllLineItemsPayloadType): Promise<LineItemModel[]> {
    const config = await apiConfiguration()
    const lineItemApi = new LineItemApi(config)

    const { status } = payload
    const response = await lineItemApi.list(status)
    return Array.from(response.data.lineItems?.values() || [])
  },
  createLineItem: async function (request: CreateLineItemRequest): Promise<CreateLineItemResponse> {
    const config = await apiConfiguration()
    const lineItemApi = new LineItemApi(config)

    const response = await lineItemApi.create(request)
    return response.data
  },
  updateLineItems: async function (request: UpdateLineItemsRequest): Promise<UpdateLineItemsResponse> {
    const config = await apiConfiguration()
    const lineItemApi = new LineItemApi(config)

    const response = await lineItemApi.update(request)
    return response.data
  },
  listTowerLineItemTemplates: async function (): Promise<LineItemTemplateDto[]> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const response = await programApi.listTowerLineItemTemplate()
    return response.data.lineItemTemplateList ?? []
  },
  createTowerLineItemTemplate: async function (payload: CreateTowerLineItemTemplateRequest): Promise<void> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    await programApi.createTowerLineItemTemplate(payload)
  },
  applyTowerLineItemTemplate: async function (payload: ApplyTowerLineItemTemplateRequest): Promise<ProgramDto> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const response = await programApi.applyTowerLineItemTemplate(payload)
    return response.data.program! as ProgramDto
  },
  listCarrierLineItemTemplates: async function (): Promise<LineItemTemplateDto[]> {
    const config = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.listCarrierLineItemTemplate()
    return response.data.lineItemTemplateList ?? []
  },
  createCarrierLineItemTemplate: async function (payload: CreateCarrierLineItemTemplateRequest): Promise<void> {
    const config = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    await indicationApi.createCarrierLineItemTemplate(payload)
  },
  applyCarrierLineItemTemplate: async function (payload: ApplyCarrierLineItemTemplateRequest): Promise<ProgramDto> {
    const config = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.applyCarrierLineItemTemplate(payload)
    return response.data.program! as ProgramDto
  },
  deleteTowerLineItemTemplate: async function (payload: DeleteTowerLineItemTemplatePayloadType): Promise<void> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { lineItemTemplateId } = payload
    await programApi.deleteTowerLineItemTemplate(lineItemTemplateId)
  }
} satisfies LineItemService
