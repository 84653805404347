<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'
import { useAcl } from 'vue-simple-acl/src'

import { useAuthStore } from '@/stores/auth'

import type { DealModel } from '@/capability/deal/types/deal-model'
import type { ExposureModel } from '@/capability/exposure/types'
import type { LineItemModel } from '@/capability/line-item/types'
import type { ProgramModel } from '@/capability/program/types'

import ProgramPanelVisibility from './program-navbar/ProgramPanelVisibility.vue'
import ProgramSaveEdit from './program-navbar/ProgramSaveEdit.vue'
import ProgramOptions from './ProgramOptions.vue'

import TaxCalculator from '@/component/billing/TaxCalculator.vue'
import ProgramActions from '@/component/program/program-navbar/ProgramActions.vue'
import ProgramLineItemsNavigation from '@/component/program/program-navbar/ProgramLineItemsNavigation.vue'
import ProgramPageNavigation from '@/component/program/program-navbar/ProgramPageNavigation.vue'
import ProgramIndicationQuoteLetterDialog from '@/component/program/ProgramIndicationQuoteLetterDialog.vue'
import ProgramLineItemsManagement from '@/component/program/ProgramLineItemsManagement.vue'

const props = defineProps({
  deal: {
    type: Object as PropType<DealModel>,
    required: true
  },
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  exposuresModel: {
    type: Array as PropType<ExposureModel[]>,
    required: true
  },
  lineItems: {
    type: Array as PropType<LineItemModel[]>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:exposuresModel', exposures: ExposureModel[]): void
}>()

const authStore = useAuthStore()
const acl = useAcl()

const lineItemNavigationVisible = computed(() => (authStore.allProgramSectionsVisibility === 'show' ? false : acl.anyRole(['rs', 'admin'])))

const exposures = computed({
  get() {
    return props.exposuresModel as ExposureModel[]
  },
  set(newValue: ExposureModel[]) {
    emit('update:exposuresModel', newValue)
  }
})
</script>

<template>
  <div class="sticky top-[75px] z-40 flex justify-between bg-white px-4 pb-3 pt-1">
    <ProgramPageNavigation :program-model />
    <div class="flex items-center justify-end space-x-2 divide-x divide-gray-300">
      <div class="flex items-center">
        <ProgramIndicationQuoteLetterDialog :file-name="`${deal.name}-quote-letter`" :program="programModel" />
        <TaxCalculator :deal="deal" :program="programModel" />
        <ProgramLineItemsManagement />
      </div>
      <ProgramLineItemsNavigation v-if="lineItemNavigationVisible" />
      <ProgramActions v-model:exposures-model="exposures" :deal :program-model />
      <ProgramPanelVisibility />
      <ProgramSaveEdit :exposures-model="exposures" :program-model />
      <div class="pl-2">
        <ProgramOptions datacy="program-options-trigger" :program="programModel" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.unselected-link {
  background-color: hsla(0, 0%, 97%);
}
</style>
