<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'
import { useAcl } from 'vue-simple-acl/src'

import { toggleElement } from '@/lib/utils'
import { useProgramStore } from '@/stores/program'

import type { LineItemNavigationItemType, ProgramSections } from '@/capability/program/types'
import { navigationItems } from '@/capability/program/utils'
import type { TowerModel } from '@/capability/tower/types'

import LineItemTemplate from '@/component/line-item-template/LineItemTemplate.vue'

const acl = useAcl()
const programStore = useProgramStore()

const props = defineProps({
  tower: {
    type: Object as PropType<TowerModel>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:tower', payload: TowerModel): void
}>()

const tower = computed({
  get(): TowerModel {
    return props.tower as TowerModel
  },
  set(newValue: TowerModel): void {
    emit('update:tower', newValue)
  }
})

const activeIconBackground = (item: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(item.location) ? 'bg-white' : 'bg-[#8d86b2]'

const activeIconColor = (item: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(item.location) ? 't-and-c' : 'white'
const buttonVariant = (item: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(item.location) ? 'primary' : 'gray'

const handleNavigation = (location: ProgramSections): void => {
  programStore.visibleSection = toggleElement(programStore.visibleSection, location)
  // window.scrollTo({ top: 99999, behavior: 'smooth' })
}
</script>

<template>
  <div v-if="props.tower" class="mt-8 flex flex-col">
    <div>
      <p class="font-semibold">
        Terms & Conditions -
        <span class="font-base">{{ props.tower.name }}</span>
      </p>
    </div>
    <div class="flex flex-wrap">
      <rq-btn
        v-for="item in navigationItems"
        :key="item.location"
        class="mx-1 my-2 flex items-center px-2 py-4"
        :variant="buttonVariant(item)"
        @click="handleNavigation(item.location)"
      >
        <rq-icon
          class="mr-1 rounded-lg p-1"
          :class="activeIconBackground(item)"
          :color="activeIconColor(item)"
          :icon="item.icon"
          size="x-large"
        />
        <span class="inline text-center">{{ item.name }}</span>
      </rq-btn>
      <LineItemTemplate v-if="acl.anyRole(['admin', 'rs'])" v-model:tower="tower" line-item-type="Tower" />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
