import moment from 'moment'

import type { DealModel } from '@/capability/deal/types/deal-model'
import type { ProgramModel } from '@/capability/program/types'
import type { DealDto } from 'typescript-core-api-client'
import { DealDtoPracticeEnum } from 'typescript-core-api-client'

export const REQUIRED_KEYS: (keyof DealModel)[] = ['status', 'policyInfo.effectiveStartDate', 'description'] as (keyof DealModel)[]

export type StateListType = {
  State: string
  Abbrev: string
  Code: string
}

export const stateList = [
  {
    State: 'Alabama',
    Abbrev: 'Ala.',
    Code: 'AL'
  },
  {
    State: 'Alaska',
    Abbrev: 'Alaska',
    Code: 'AK'
  },
  {
    State: 'Arizona',
    Abbrev: 'Ariz.',
    Code: 'AZ'
  },
  {
    State: 'Arkansas',
    Abbrev: 'Ark.',
    Code: 'AR'
  },
  {
    State: 'California',
    Abbrev: 'Calif.',
    Code: 'CA'
  },
  {
    State: 'Colorado',
    Abbrev: 'Colo.',
    Code: 'CO'
  },
  {
    State: 'Connecticut',
    Abbrev: 'Conn.',
    Code: 'CT'
  },
  {
    State: 'Delaware',
    Abbrev: 'Del.',
    Code: 'DE'
  },
  {
    State: 'District of Columbia',
    Abbrev: 'D.C.',
    Code: 'DC'
  },
  {
    State: 'Florida',
    Abbrev: 'Fla.',
    Code: 'FL'
  },
  {
    State: 'Georgia',
    Abbrev: 'Ga.',
    Code: 'GA'
  },
  {
    State: 'Hawaii',
    Abbrev: 'Hawaii',
    Code: 'HI'
  },
  {
    State: 'Idaho',
    Abbrev: 'Idaho',
    Code: 'ID'
  },
  {
    State: 'Illinois',
    Abbrev: 'Ill.',
    Code: 'IL'
  },
  {
    State: 'Indiana',
    Abbrev: 'Ind.',
    Code: 'IN'
  },
  {
    State: 'Iowa',
    Abbrev: 'Iowa',
    Code: 'IA'
  },
  {
    State: 'Kansas',
    Abbrev: 'Kans.',
    Code: 'KS'
  },
  {
    State: 'Kentucky',
    Abbrev: 'Ky.',
    Code: 'KY'
  },
  {
    State: 'Louisiana',
    Abbrev: 'La.',
    Code: 'LA'
  },
  {
    State: 'Maine',
    Abbrev: 'Maine',
    Code: 'ME'
  },
  {
    State: 'Maryland',
    Abbrev: 'Md.',
    Code: 'MD'
  },
  {
    State: 'Massachusetts',
    Abbrev: 'Mass.',
    Code: 'MA'
  },
  {
    State: 'Michigan',
    Abbrev: 'Mich.',
    Code: 'MI'
  },
  {
    State: 'Minnesota',
    Abbrev: 'Minn.',
    Code: 'MN'
  },
  {
    State: 'Mississippi',
    Abbrev: 'Miss.',
    Code: 'MS'
  },
  {
    State: 'Missouri',
    Abbrev: 'Mo.',
    Code: 'MO'
  },
  {
    State: 'Montana',
    Abbrev: 'Mont.',
    Code: 'MT'
  },
  {
    State: 'Nebraska',
    Abbrev: 'Nebr.',
    Code: 'NE'
  },
  {
    State: 'Nevada',
    Abbrev: 'Nev.',
    Code: 'NV'
  },
  {
    State: 'New Hampshire',
    Abbrev: 'N.H.',
    Code: 'NH'
  },
  {
    State: 'New Jersey',
    Abbrev: 'N.J.',
    Code: 'NJ'
  },
  {
    State: 'New Mexico',
    Abbrev: 'N.M.',
    Code: 'NM'
  },
  {
    State: 'New York',
    Abbrev: 'N.Y.',
    Code: 'NY'
  },
  {
    State: 'North Carolina',
    Abbrev: 'N.C.',
    Code: 'NC'
  },
  {
    State: 'North Dakota',
    Abbrev: 'N.D.',
    Code: 'ND'
  },
  {
    State: 'Ohio',
    Abbrev: 'Ohio',
    Code: 'OH'
  },
  {
    State: 'Oklahoma',
    Abbrev: 'Okla.',
    Code: 'OK'
  },
  {
    State: 'Oregon',
    Abbrev: 'Ore.',
    Code: 'OR'
  },
  {
    State: 'Pennsylvania',
    Abbrev: 'Pa.',
    Code: 'PA'
  },
  {
    State: 'Rhode Island',
    Abbrev: 'R.I.',
    Code: 'RI'
  },
  {
    State: 'South Carolina',
    Abbrev: 'S.C.',
    Code: 'SC'
  },
  {
    State: 'South Dakota',
    Abbrev: 'S.D.',
    Code: 'SD'
  },
  {
    State: 'Tennessee',
    Abbrev: 'Tenn.',
    Code: 'TN'
  },
  {
    State: 'Texas',
    Abbrev: 'Tex.',
    Code: 'TX'
  },
  {
    State: 'Utah',
    Abbrev: 'Utah',
    Code: 'UT'
  },
  {
    State: 'Vermont',
    Abbrev: 'Vt.',
    Code: 'VT'
  },
  {
    State: 'Virginia',
    Abbrev: 'Va.',
    Code: 'VA'
  },
  {
    State: 'Washington',
    Abbrev: 'Wash.',
    Code: 'WA'
  },
  {
    State: 'West Virginia',
    Abbrev: 'W.Va.',
    Code: 'WV'
  },
  {
    State: 'Wisconsin',
    Abbrev: 'Wis.',
    Code: 'WI'
  },
  {
    State: 'Wyoming',
    Abbrev: 'Wyo.',
    Code: 'WY'
  }
]

export const stateItems = stateList.map(({ Code }) => Code)

export const stateItemsWithMulti = ['MULTI', ...stateItems]

export function getDealPracticeDisplay(practice: DealDtoPracticeEnum): string {
  switch (practice) {
    case DealDtoPracticeEnum.CommercialCasualty:
      return 'Commercial Casualty'
    case DealDtoPracticeEnum.CommercialProperty:
      return 'Commercial Property'
    case DealDtoPracticeEnum.CommercialPropertyCasualty:
      return 'Commercial Property & Casualty'
    case DealDtoPracticeEnum.PersonalCasualty:
      return 'Personal Casualty'
    case DealDtoPracticeEnum.PersonalProperty:
      return 'Personal Property'
    case DealDtoPracticeEnum.PersonalPropertyCasualty:
      return 'Personal Property & Casualty'
    default:
      return 'Unknown'
  }
}

/**
 * return 365 days if effectStartDate to effectEndDate does not overlap with leap year, otherwise return 366 days
 * check if startDate or endDate is in leap year
 * @param payload
 */
export function getTerms(payload: DealModel | ProgramModel | undefined) {
  const startDate = payload?.policyInfo?.effectiveStartDate
  const endDate = payload?.policyInfo?.effectiveEndDate
  if (!startDate || !endDate) return 0

  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)
  const startYear = startDateObj.getUTCFullYear()
  const endYear = endDateObj.getUTCFullYear()
  const isStartYearLeapYear = startYear % 4 === 0 && (startYear % 100 !== 0 || startYear % 400 === 0)
  const isEndYearLeapYear = endYear % 4 === 0 && (endYear % 100 !== 0 || endYear % 400 === 0)
  if (isStartYearLeapYear && Date.parse(startYear + '-02-29') >= Date.parse(startDate)) {
    return 366
  } else if (isEndYearLeapYear && Date.parse(endYear + '-02-29') <= Date.parse(endDate)) {
    return 366
  } else {
    return 365
  }
}

export function getTermsOfConstruction(payload: DealModel | ProgramModel | undefined) {
  const startDate = payload?.policyInfo?.effectiveStartDate
  const endDate = payload?.policyInfo?.effectiveEndDate
  if (!startDate || !endDate) return 0

  const endDateObj = new Date(endDate)
  endDateObj.setUTCHours(0, 0, 0)
  const startDateObj = new Date(startDate)
  startDateObj.setUTCHours(0, 0, 0)
  const diff = moment(endDateObj).diff(moment(startDateObj), 'days', true)
  return Math.round(diff)
}

export const createDeal = (payload: DealDto): DealModel => {
  const clientInfo = payload.clientInfo || {}
  const retailerAddress = payload.retailerInfo?.address || {}
  const retailerInfo = { ...(payload.retailerInfo || {}), address: retailerAddress }
  const projectAddress = payload.projectInfo?.address || {}
  const projectInfo = { ...(payload.projectInfo || {}), address: projectAddress }
  return { ...payload, clientInfo, retailerInfo, projectInfo }
}
