import cloneDeep from 'lodash.clonedeep'
import sortBy from 'lodash.sortby'

import { generateUuid } from '@/lib/utils/id'

import type { LineItemTargetModel } from '@/capability/line-item/types'
import { DEFAULT_TOWER_LIMIT } from '@/capability/tower/constants'
import type { TowerModel } from '@/capability/tower/types'
import { TowerDtoPerilsEnum } from 'typescript-core-api-client'
import type { MoneyDto } from 'typescript-core-api-client/dist/api'

export const getOrderedTowers = (towers: TowerModel[]): TowerModel[] =>
  sortBy(
    towers.filter((tower) => tower.displayOrder != null && !isNaN(+tower.displayOrder)),
    (tower) => +tower.displayOrder!
  )

export function getNextTowerOrder(towers: TowerModel[]): string {
  if (!towers.length) return '0'
  const orderedTowers = getOrderedTowers(towers)
  return (+orderedTowers[orderedTowers.length - 1].displayOrder! + 1).toString()
}

export function assignOrderToUnorderedTowers(towers: TowerModel[]): TowerModel[] {
  const orderedTowers = getOrderedTowers(towers).map((tower, idx) => {
    const towerClone = cloneDeep(tower)
    towerClone.displayOrder = idx.toString()
    return towerClone
  })

  const unorderedTowers = towers
    .filter((tower) => !orderedTowers.map((e) => e.id).includes(tower.id))
    .map((tower, idx) => {
      const towerClone = cloneDeep(tower)
      towerClone.displayOrder = (idx + (orderedTowers.length ?? 0)).toString()
      return towerClone
    })
  return sortBy([...orderedTowers, ...unorderedTowers], (tower) => +tower.displayOrder!)
}

/** This function existed before with the nullish coalescing. I don't want to remove it and run the risk of
 * breaking existing functionality.
 *
 * For the new functionality with TowerModelImpl removed (that matches the updated layer functionality)
 * as of 26-Feb-2025 see the `getTowerLimit` method
 *
 */
export const getTowerCoverageLimit = (tower: TowerModel): number => getTowerLimit(tower) ?? 0

export const getTowerLimit = (tower: TowerModel): number | undefined => tower.coverage?.limit?.amount

export const setTowerLimit = (tower: TowerModel, amount: number) => {
  if (!tower) {
    return
  }

  // Initialize coverage if it doesn't exist
  tower.coverage = tower.coverage || {}

  // Initialize limit if it doesn't exist, with a minimal currency object
  if (!tower.coverage.limit) {
    tower.coverage.limit = { amount } as MoneyDto
  } else {
    // Just update the amount if limit already exists
    tower.coverage.limit.amount = amount
  }
}

export const getTowerExcess = (tower: TowerModel): number | undefined => tower.coverage?.excess?.amount

export const setTowerExcess = (tower: TowerModel, amount: number) => {
  if (!tower) {
    return
  }

  // Initialize coverage if it doesn't exist
  tower.coverage = tower.coverage || {}

  // Initialize excess if it doesn't exist, with a minimal currency object
  if (!tower.coverage.excess) {
    tower.coverage.excess = { amount } as MoneyDto
  } else {
    // Just update the amount if excess already exists
    tower.coverage.excess.amount = amount
  }
}

export interface CreateTowerPayloadType {
  id?: string
  name?: string
  limit?: number
  excess?: number
  perils?: TowerDtoPerilsEnum[]
  lineItemTargets?: LineItemTargetModel[]
}
export const createTower = (payload: CreateTowerPayloadType) => {
  const id = payload.id || generateUuid()
  const name = payload.name
  const perils = payload.perils || [TowerDtoPerilsEnum.Unknown]
  const lineItemTargets = payload.lineItemTargets || []
  const tower: TowerModel = {
    id,
    name,
    perils,
    lineItemTargets
  } as TowerModel
  if (payload.limit != null) {
    setTowerLimit(tower, payload.limit)
  } else {
    setTowerLimit(tower, DEFAULT_TOWER_LIMIT)
  }
  if (payload.excess != null) {
    setTowerExcess(tower, payload.excess)
  } else {
    setTowerExcess(tower, 0)
  }
  return tower
}

export * from './colors'
export * from './layers'
export * from './schematic'
