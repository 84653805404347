import { computed } from 'vue'
import { createAcl, defineAclRules } from 'vue-simple-acl/src'

import { isAdmin, isCarrier, isClient, isCoBroker, isInternalUser, isRetailer, isRiskSpecialist } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'

import type { DealModel } from '@/capability/deal/types/deal-model'
import { DealPrivilege, DocumentPrivilege, MessagePrivilege, type Privilege, ProgramPrivilege } from '@/capability/privilege/types'
import type { ProgramModel } from '@/capability/program/types'
import type { UserModel } from '@/capability/user/types'

import router from '../../router'

export function createPrivilegePrefix(privilege: Privilege): string {
  return `${privilege.getPrefix()}_${privilege.getName()}`
}

export function createPrivilegeName(privilege: Privilege, entityId: string): string {
  return `${createPrivilegePrefix(privilege)}_${entityId}`
}

/**
 * create the rules based off from the privileges. Admin and RiskSpecialist can do everything.
 */
const rules = () =>
  defineAclRules((setRule: any) => {
    const authStore = useAuthStore()

    setRule('admin', (user: UserModel) => isAdmin(user))
    setRule('risk-specialist', (user: UserModel) => isRiskSpecialist(user))
    setRule('rs', (user: UserModel) => isRiskSpecialist(user))
    setRule('retailer', (user: UserModel) => isRetailer(user))
    setRule('carrier', (user: UserModel) => isCarrier(user))
    setRule('client', (user: UserModel) => isClient(user))
    setRule('cobroker', (user: UserModel) => isCoBroker(user))

    DealPrivilege.getAll().forEach((privilege) => {
      setRule(privilege.ruleName, (user: UserModel, deal: DealModel) => {
        return user && (isInternalUser(user) || (deal && authStore.getPrivileges.includes(createPrivilegeName(privilege, deal.id!))))
      })
    })

    ProgramPrivilege.getAll().forEach((privilege) => {
      setRule(privilege.ruleName, (user: UserModel, program: ProgramModel) => {
        return user && (isInternalUser(user) || (program && authStore.getPrivileges.includes(createPrivilegeName(privilege, program.id!))))
      })
    })

    MessagePrivilege.getAll().forEach((privilege) => {
      setRule(privilege.ruleName, (user: UserModel, message: any) => {
        return user && (isInternalUser(user) || (message && authStore.getPrivileges.includes(createPrivilegeName(privilege, message.id!))))
      })
    })

    DocumentPrivilege.getAll().forEach((privilege) => {
      setRule(privilege.ruleName, (user: UserModel, document: any) => {
        return (
          user && (isInternalUser(user) || (document && authStore.getPrivileges.includes(createPrivilegeName(privilege, document.id!))))
        )
      })
    })

    setRule('program-read-market-detailed', (user: UserModel, marketId: string) => {
      if (user && (isInternalUser(user) || isRetailer(user))) {
        return true
      }
      return user.organizationId === marketId
    })
  })

const simpleAcl = createAcl({
  user: computed(() => {
    const authStore = useAuthStore()
    return authStore.viewedAsUser ?? authStore.user
  }),
  rules,
  router
})

export default simpleAcl
