import { emptyStringIfEmpty, isEmpty, isPresent, undefineIfEmpty } from '@/lib/utils/formatting'
import { generateUuid } from '@/lib/utils/id'

import type { SystemEvent } from './types'

/**
 * Everything below this line is ENTIRELY SHARED BETWEEN ldrq-web and arqu-web
 */

export const CUSTOM_CRUD_VERIFY = 'verify'
export const CUSTOM_CRUD_COMPLETE = 'complete'
export const CRUDL_NEW = 'new'
export const RESOURCE_TYPE_EMAIL = 'email'
export const RESOURCE_TYPE_USER = 'user'
export const PAGE_VIEW_WORKER_ACTION = 'page-view'
export const PAGE_VIEW_CODE = 'page-view'
export const PROGRAMMATIC_CODE = 'programmatic'
export const USER_ACTIVITY_CODE = 'user-activity'
export const USER_SESSION_RESOURCE_TYPE = 'user-session'
export const CLIENT_SAYS_INVALID = 'invalid-client-side'
export const CUSTOM_CRUD_DOES_ALREADY_EXIST = 'check-if-exists'
export const CUSTOM_CRUD_CHECK_IS_PRESENT = 'check-is-present'
// export const CUSTOM_CRUD_DOES_EMAIL_ALREADY_EXIST = 'check-if-email-exists'
export const CUSTOM_CRUD_PASSWORD_CHANGE_REQUIRED = 'password-change-required'
export const CUSTOM_CRUD_NEW_REQUIRED = 'new-required'
export const CRUDL_RESULT_PRESENT = 'present'
export const CRUDL_RESULT_ABSENT = 'absent'

export const PAGE_ID_ONBOARDING = 'onboarding'
// export const LEGACY_NAME_ONBOARDING_PREFIX = 'OnBoarding'
// export const LEGACY_NAME_ONBOARDING_PAGEVIEW = LEGACY_NAME_ONBOARDING_PREFIX + '-view'
export const PAGE_ID_LOGIN = 'login'
export const WORKER_ACTION_SUBMIT = 'submit'
export const CRUDL_RESULT_PENDING = 'pending'
export const CRUDL_RESULT_CREATE = 'create'
export const CRUDL_RESULT_TRUE = 'true'
export const CRUDL_RESULT_FALSE = 'false'
export const CRUDL_RESULT_UNKNOWN = 'unknown'
export const CRUDL_RESULT_UNVERIFIED = 'unverified'
export const WORKER_ACTION_EMAIL_SENT = 'email-sent'
export const WORKER_ACTION_SENT = 'sent'
export const WORKER_ACTION_SEND = 'send'
export const CRUDL_RESULT_OK = 'ok'
export const CRUDL_RESULT_SUCCESS = 'success'
export const CRUDL_RESULT_FAIL = 'fail'
export const SUBRESOURCE_USER_VERIFICATION = 'user-verification'
export const SUBRESOURCE_CHANGE_PASSWORD_TOKEN = 'change-password-token'

export const CRUDL_SEND = 'send'
export const CRUDL_RESULT_ACTIVE = 'active'
export const CRUDL_RESULT_INITIAL = 'initial'

// export const PROGRAMMATIC_WORKER_ACTION = 'programmatic'
// export const USER_SESSION_RESOURCE_TYPE = 'user-session'
// export const CLIENT_SAYS_INVALID = 'invalid-client-side'
// export const CUSTOM_CRUD_DOES_ALREADY_EXIST = 'does-already-exist'
// export const CUSTOM_CRUD_NEW_REQUIRED = 'new-required'

/**
 * Can we/Should we Generate the legacyEventName?
 *
 * legacyEventName kinda means uniqueProgramaticName
 *
 * TODO: Ordering?  JSON?
 */
export function buildUniqueProgrammaticEventName(systemEvent: SystemEvent): string {
  return [
    systemEvent.pageId,
    systemEvent.workerAction,
    systemEvent.resourceType,
    systemEvent.subResource,
    systemEvent.resourceCrudl,
    systemEvent.resourceCrudlResult,
    systemEvent.reason
  ]
    .filter((x) => emptyStringIfEmpty(x))
    .join('::')
}

export function buildOnboardingPageViewTemplate(
  resourceCrudl: string | null | undefined,
  emailAddress: string | null | undefined
): SystemEvent {
  const event = createSystemEvent()
  event.pageId = PAGE_ID_ONBOARDING

  if (isEmpty(event.resourceType)) {
    event.resourceType = RESOURCE_TYPE_USER
  }
  if (isEmpty(event.subResource)) {
    event.subResource = RESOURCE_TYPE_EMAIL
  }

  if (isPresent(resourceCrudl)) {
    event.resourceCrudl = resourceCrudl as string
    event.legacyEventName = event.legacyEventName + '-' + resourceCrudl
  }

  if (isPresent(emailAddress)) {
    event.resourceInstanceName = emailAddress!!
    event.snapshotUserBlurb = emailAddress!!
  }

  if (isEmpty(event.legacyEventName)) {
    event.legacyEventName = buildUniqueProgrammaticEventName(event)
  }

  return event
}

export function buildOnboardingEventTemplate(attrs: {
  email: string | null | undefined
  workerAction: string | null | undefined
  resourceType: string | null | undefined
  resourceCrudl: string | null | undefined
  resourceCrudlResult: string | null | undefined
  subResource: string | null | undefined
  reason: string | null | undefined
  code: string | null | undefined
}): SystemEvent {
  const event = createSystemEvent()
  event.pageId = PAGE_ID_ONBOARDING
  event.resourceType = undefineIfEmpty(attrs?.resourceType)
  // event.legacyEventName = LEGACY_NAME_ONBOARDING_PREFIX
  event.workerAction = undefineIfEmpty(attrs?.workerAction)
  event.resourceCrudl = undefineIfEmpty(attrs?.resourceCrudl)
  event.subResource = undefineIfEmpty(attrs?.subResource)
  event.resourceInstanceName = undefineIfEmpty(attrs?.email)
  event.resourceCrudlResult = undefineIfEmpty(attrs?.resourceCrudlResult)
  event.reason = undefineIfEmpty(attrs?.reason)
  event.code = undefineIfEmpty(attrs?.code)

  if (isEmpty(event.legacyEventName)) {
    event.legacyEventName = buildUniqueProgrammaticEventName(event)
  }
  return event
}

export function buildLoginPageViewTemplate(emailAddress: string | null | undefined): SystemEvent {
  const event = createSystemEvent()
  event.pageId = 'login'
  event.resourceType = USER_SESSION_RESOURCE_TYPE
  event.resourceCrudl = CRUDL_NEW
  if (isPresent(emailAddress)) {
    event.resourceInstanceName = emailAddress!!
    event.snapshotUserBlurb = emailAddress!!
  }
  return event
}

export function buildDocumentExtractPageViewTemplate(emailAddress: string | null | undefined): SystemEvent {
  const event = createSystemEvent()
  event.pageId = 'document-extract'
  event.resourceType = USER_SESSION_RESOURCE_TYPE
  event.resourceCrudl = CRUDL_NEW
  if (isPresent(emailAddress)) {
    event.resourceInstanceName = emailAddress!!
    event.snapshotUserBlurb = emailAddress!!
  }
  return event
}

export const createSystemEvent = (eventData: Partial<SystemEvent> = {}): SystemEvent => {
  // Generate a UUID for the event if not provided
  if (!eventData.id) {
    eventData.id = generateUuid()
  }

  // Set default eventAt to current time if not provided
  if (!eventData.eventAt) {
    eventData.eventAt = new Date().toISOString()
  }

  // Set eventSchemaVersion if not provided
  if (!eventData.eventSchemaVersion) {
    eventData.eventSchemaVersion = 'v1'
  }

  return {
    ...eventData
  }
}
