import cloneDeep from 'lodash.clonedeep'

import type { LayerStyle, StyledLayer } from '@/lib/types/tower-svg'
import { generateUuid } from '@/lib/utils/id'

import type { LayerModel } from '@/capability/layer/types'
import type { LayerTargetModel } from '@/capability/program/LayerTargetModel'
import { generateLabel } from '@/capability/tower/utils'
import type { CarrierDto, CoverageDto, MoneyDto } from 'typescript-core-api-client/dist/api'

export type FillInLayerGapType = {
  nonPlugLayers: LayerModel[]
  plugLayers: LayerModel[]
  towerMax: number
  towerMin: number
}

type FillInLayerGapOutput = {
  filledLayers: LayerModel[]
  plugs: LayerModel[]
}

export const createLayerFromExcessLimit = (payload: { excess?: number; limit?: number }) => createLayer(payload)

export const getLayerLimit = (layer: LayerModel): number | undefined => layer.coverage?.limit?.amount

export const setLayerLimit = (layer: LayerModel, amount: number) => {
  if (!layer) {
    return
  }

  // Initialize coverage if it doesn't exist
  layer.coverage = layer.coverage || {}

  // Initialize limit if it doesn't exist, with a minimal currency object
  if (!layer.coverage.limit) {
    layer.coverage.limit = { amount } as MoneyDto
  } else {
    // Just update the amount if limit already exists
    layer.coverage.limit.amount = amount
  }
}

export const getLayerExcess = (layer: LayerModel): number | undefined => layer.coverage?.excess?.amount

export const setLayerExcess = (layer: LayerModel, amount: number) => {
  if (!layer) {
    return
  }

  // Initialize coverage if it doesn't exist
  layer.coverage = layer.coverage || {}

  // Initialize excess if it doesn't exist, with a minimal currency object
  if (!layer.coverage.excess) {
    layer.coverage.excess = { amount } as MoneyDto
  } else {
    // Just update the amount if excess already exists
    layer.coverage.excess.amount = amount
  }
}

export interface CreateLayerPayloadType {
  id?: string
  layerTarget?: LayerTargetModel
  coverage?: CoverageDto
  layers?: LayerModel[]
  carriers?: CarrierDto[]
  name?: string
  plug?: boolean
  limit?: number
  excess?: number
  percentage?: number
}
export const createLayer = (payload: CreateLayerPayloadType): LayerModel => {
  const id = payload.id || generateUuid()
  const layerTarget = payload.layerTarget || { participation: '100', premium: '0' }
  const coverage = payload.coverage || {}
  const layers = payload.layers || []
  const carriers = payload.carriers || []
  const name = payload.name
  const plug = payload.plug || false
  const percentage = payload.percentage || undefined
  const layer: LayerModel = {
    id,
    layerTarget,
    coverage,
    layers,
    carriers,
    name,
    plug,
    percentage
  } as LayerModel
  if (payload.limit != null) {
    setLayerLimit(layer, payload.limit)
  }
  if (payload.excess != null) {
    setLayerExcess(layer, payload.excess)
  }
  return layer
}

export const fillInLayerGaps = (payload: FillInLayerGapType): FillInLayerGapOutput => {
  const { plugLayers, nonPlugLayers, towerMax, towerMin } = payload
  let plugLayersClone = cloneDeep(plugLayers)
  let currentExcess: number = towerMin ?? 0
  const filledLayers: LayerModel[] = [] as LayerModel[]
  nonPlugLayers.forEach((l) => {
    const layer = cloneDeep(l)
    const limit = +getLayerLimit(layer)!
    const excess = +getLayerExcess(layer)!
    if (excess !== currentExcess) {
      let plugLayer: LayerModel
      const idx = plugLayersClone.findIndex((e) => +getLayerExcess(l)! < excess && limit + excess >= currentExcess)
      if (idx < 0) {
        plugLayer = createLayer({ plug: true, excess: currentExcess, limit: excess - currentExcess })
      } else {
        plugLayer = cloneDeep(plugLayersClone[idx])
        setLayerExcess(plugLayer, currentExcess)
        setLayerLimit(plugLayer, excess - currentExcess)
      }
      filledLayers.push(plugLayer)
      plugLayersClone = plugLayersClone.filter((e) => e.id !== plugLayer.id)
    }
    currentExcess = limit + excess
    filledLayers.push(layer)
  })
  if (currentExcess < towerMax) {
    const excess = currentExcess
    const limit = towerMax - currentExcess
    let plugLayer: LayerModel
    const idx = plugLayersClone.findIndex((l) => +getLayerExcess(l)! < excess && limit + excess >= currentExcess)
    if (idx < 0) {
      plugLayer = createLayer({ plug: true, excess, limit })
    } else {
      plugLayer = cloneDeep(plugLayersClone[idx])
      setLayerExcess(plugLayer, excess)
      setLayerLimit(plugLayer, limit)
    }
    filledLayers.push(plugLayer)
  }
  plugLayersClone = filledLayers.filter((l) => l.plug)
  return { filledLayers, plugs: plugLayersClone }
}

export const getStyledLayers = (layers: LayerModel[], towerMax: number, isInvalidTower?: boolean): StyledLayer[] => {
  return layers.map((layer) => {
    const heightVal = Math.min(+getLayerLimit(layer)! / towerMax, (towerMax - getLayerExcess(layer)!) / towerMax) * 100
    const bottomVal = (+getLayerExcess(layer)! / towerMax) * 100

    const style: LayerStyle = {
      bottom: `${bottomVal}%`,
      height: `${heightVal}%`
    }
    if (isInvalidTower) {
      style.backgroundColor = '#F44336'
    }
    return { layer, style }
  })
}

export const findFirstLayerGap = (layers: LayerModel[], towerMax: number): { excess: number; limit: number } => {
  let excess = 0
  let limit = 0
  let top = 0

  for (const layer of layers) {
    top += getLayerLimit(layer)!
    if (getLayerExcess(layer) !== excess) {
      limit = getLayerExcess(layer)! - excess
      return { limit, excess }
    } else {
      excess = getLayerExcess(layer)! + getLayerLimit(layer)!
    }
  }
  if (top !== towerMax) {
    excess = top
    limit = towerMax - top
  }
  return { excess, limit }
}

export type GetNestedLayerByIdPayloadType = {
  layer: LayerModel
  layerId: string
}
export function getNestedLayerById({ layer, layerId }: GetNestedLayerByIdPayloadType): LayerModel | undefined {
  if (layer.layers == null) {
    return undefined
  }
  for (const child of layer.layers) {
    if (child.id === layerId) {
      return child
    }
    const segment = getNestedLayerById({ layer: child, layerId })
    if (segment != null) {
      return segment
    }
  }
  return undefined
}

export function getAllLayerLayers(_layer?: LayerModel): LayerModel[] {
  if (!_layer) return []
  const layers = _layer.layers || []
  for (const layer of layers) {
    layers.push(...getAllLayerLayers(layer))
  }
  return layers
}

export const getNameWithDefault = (layer: LayerModel) => {
  if (!layer.name || layer.name === 'untitled') {
    layer.name = generateLabel(layer)
  }
  return layer.name
}

export default {
  fillInLayerGaps
}
