import cloneDeep from 'lodash.clonedeep'

import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import type { DealModel } from '@/capability/deal/types'
import { exposureService } from '@/capability/exposure/service'
import type { ExposureModel } from '@/capability/exposure/types'
import type { LineItemTargetModel } from '@/capability/line-item/types'
import { programService } from '@/capability/program/service'
import type { ProgramModel } from '@/capability/program/types'
import type { LineItemNavigationItemType } from '@/capability/program/types'

export const navigationItems: LineItemNavigationItemType[] = [
  {
    name: 'Limits & Sublimits',
    icon: 'lucide:arrow-up-wide-narrow',
    location: 'limits'
  },
  {
    name: 'Deductibles',
    icon: 'lucide:arrow-up-to-line',
    location: 'deductibles'
  },
  {
    name: 'Form & Endorsement',
    icon: 'lucide:file-text',
    location: 'form'
  }
]

export const getLineItem = (program: ProgramModel, towerId: string, lineItemId: string): LineItemTargetModel | undefined =>
  ((program.towers ?? []).find((tower) => tower.id === towerId)?.lineItemTargets ?? []).find(
    (lineItem) => lineItem.lineItemId === lineItemId
  )

export function addLineItem(program: ProgramModel, towerId: string, lineItem: LineItemTargetModel): ProgramModel {
  const copyOfProgram = cloneDeep(program)
  copyOfProgram.towers = (copyOfProgram.towers ?? []).map((tower) => {
    const copyOfTower = cloneDeep(tower)
    if (copyOfTower.id === towerId) {
      copyOfTower.lineItemTargets = copyOfTower.lineItemTargets || []
      copyOfTower.lineItemTargets.push(lineItem)
    }
    return copyOfTower
  })
  return copyOfProgram
}

export type HandleSavePayloadType = {
  programModel: ProgramModel
  exposuresModel: ExposureModel[]
}
export async function handleSave(payload: HandleSavePayloadType): Promise<void> {
  const { programModel, exposuresModel } = payload
  const programStore = useProgramStore()
  const notificationStore = useNotificationStore()
  try {
    await programStore.editProgram(programModel as ProgramModel)
    await exposureService.updateExposure({ exposures: exposuresModel })
    programStore.$patch({ isProgramEditable: false })
    notificationStore.publishSuccessMessage('Successfully updated program')
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  }
}

export interface ExportAsExcelPayloadType {
  deal: DealModel
  program: ProgramModel
}
export const exportAsExcel = async ({ deal, program }: ExportAsExcelPayloadType) => {
  const response = await programService.exportAsExcel({ program })
  const blob = response.data
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onload = (e: ProgressEvent<FileReader>) => {
    const a = document.createElement('a')
    const dateStr = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.now())
    a.download = deal.clientInfo?.name + '-' + program.title + '-' + dateStr + '.xlsx'
    a.href = e.target?.result as string
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
