// /src/stores/deal/kickoff/index.ts

import { useRouter } from 'vue-router'
import type { RemovableRef } from '@vueuse/core'
import { useSessionStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth'
import type {
  InfoFormStateType,
  LocationFormStateType,
  ProgramFormStateType,
  RetailerFormStateType,
  ReviewFormStateType
} from '@/stores/deal/kickoff/types'
import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import { dealReadService } from '@/capability/deal/services/read'
import { dealWriteService } from '@/capability/deal/services/write'
import type { DealModel } from '@/capability/deal/types'
import type { AssignableUser } from '@/capability/deal/types/deal-create-new'
import { messagesService } from '@/capability/messages/service'
import type { ProgramTemplateListModel } from '@/capability/program/types'
import type { DealDtoVerticalsEnum, MessageDto, ProgramDtoProductEnum } from 'typescript-core-api-client'
import { DealConfigDtoAssetProfileEnum, DealDtoPolicyTermEnum, DealDtoStatusEnum } from 'typescript-core-api-client'

export type StepType = {
  step: number
  id: string
  title: string
  icon: string
  route: string
}

export const useDealKickoffStore = defineStore('dealKickoff', () => {
  const authStore = useAuthStore()
  const programStore = useProgramStore()
  const notificationStore = useNotificationStore()
  const router = useRouter()
  const steps: StepType[] = [
    {
      step: 1,
      id: 'info',
      title: 'Deal Info',
      icon: 'lucide:info',
      route: 'DealCreateNewInfoStep'
    },
    {
      step: 2,
      id: 'location',
      title: 'Location',
      icon: 'lucide:map-pinned',
      route: 'DealCreateNewLocationStep'
    },
    {
      step: 3,
      id: 'retailer',
      title: 'Retailer',
      icon: 'lucide:user-round-plus',
      route: 'DealCreateNewRetailerStep'
    },
    {
      step: 4,
      id: 'program',
      title: 'Program',
      icon: 'lucide:chart-no-axes-column',
      route: 'DealCreateNewProgramStep'
    },
    {
      step: 5,
      id: 'review',
      title: 'Review',
      icon: 'lucide:list-check',
      route: 'DealCreateNewReviewStep'
    }
  ]

  const validatedSteps = useSessionStorage<Set<string>>('validated-steps', new Set())

  function markStepValidated(stepId: string) {
    validatedSteps.value.add(stepId)
  }

  function markStepNotValidated(stepId: string) {
    validatedSteps.value.delete(stepId)
  }

  function isStepRequired(stepId: string): boolean {
    // Location step is not required for MultiAsset
    if (stepId === 'location' && assetProfile.value === DealConfigDtoAssetProfileEnum.MultiAsset) {
      return false
    }
    return true
  }

  function isStepValid(stepId: string): boolean {
    // If step is not required, consider it always valid
    if (!isStepRequired(stepId)) {
      return true
    }
    return validatedSteps.value.has(stepId)
  }

  function clearValidatedSteps() {
    validatedSteps.value.clear()
  }

  function canNavigateToStep(targetStepNumber: number): boolean {
    return steps
      .filter((step) => step.step < targetStepNumber)
      .map((step) => step.id)
      .every((stepId) => !isStepRequired(stepId) || isStepValid(stepId))
  }

  function getNextValidStep(currentStep: number): number {
    const nextStep = currentStep + 1
    if (nextStep > steps.length) {
      return steps.length
    }
    if (
      nextStep === 2 && // Location is step 2
      assetProfile.value === DealConfigDtoAssetProfileEnum.MultiAsset
    ) {
      return 3 // Skip to Retailer (step 3)
    }
    return nextStep
  }

  function getPreviousValidStep(currentStep: number): number {
    const prevStep = currentStep - 1
    if (prevStep < 1) {
      return 1
    }
    if (
      prevStep === 2 && // Location is step 2
      assetProfile.value === DealConfigDtoAssetProfileEnum.MultiAsset
    ) {
      return 1 // Skip back to Info (step 1)
    }
    return prevStep
  }

  const deal = useSessionStorage<DealModel>('kickoff-deal', {} as DealModel)
  async function loadDeal(dealId: string) {
    deal.value = await dealReadService.getDealById({ dealId })
    dataUpdated.value = true
    existingDealId.value = dealId
  }

  const message = useSessionStorage<MessageDto>('kickoff-message', {} as MessageDto)
  async function loadMessage(messageId: string) {
    try {
      message.value = (await messagesService.getMessage({ messageId }))?.message ?? null
    } catch (err) {
      message.value = null
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    }
  }

  const initialized = useSessionStorage<boolean>('initialized', false)
  const dataUpdated = useSessionStorage<boolean>('data-updated', false)
  const existingDealId = useSessionStorage<string>('existing-deal-id', '')

  const id = useSessionStorage<string>('id', '')
  const name = useSessionStorage<string>('name', '')
  const contractor = useSessionStorage<string>('contractor', '')
  const effectiveStartDate = useSessionStorage<string>('effectiveStartDate', '')
  const effectiveEndDate = useSessionStorage<string>('effectiveEndDate', '')
  const vertical = useSessionStorage<string>('vertical', '')
  const projectName = useSessionStorage<string>('projectName', '')
  const retailerName = useSessionStorage<string>('retailerName', '')
  const retailerOrganizationId = useSessionStorage<string>('retailerOrganizationId', '')
  const retailerEmailsToAssign = useSessionStorage<string[]>('retailerEmailsToAssign', [])
  const address = useSessionStorage<string>('address', '')
  const city = useSessionStorage<string>('city', '')
  const state = useSessionStorage<string>('state', '')
  const zip = useSessionStorage<string>('zip', '')
  const lat = useSessionStorage<string>('lat', '')
  const lng = useSessionStorage<string>('lng', '')
  const userEmail = useSessionStorage<string>('userEmail', '')
  const userEmailsToAssign = useSessionStorage<AssignableUser[]>('userEmailsToAssign', [])
  const userGroupsToAssign = useSessionStorage<string[]>('userGroupsToAssign', [])
  const assetProfile = useSessionStorage<DealConfigDtoAssetProfileEnum>('assetProfile', DealConfigDtoAssetProfileEnum.SingleAsset)
  const programTemplate = useSessionStorage<string>('programTemplate', '')
  const programName = useSessionStorage<string>('programName', '')
  const programProduct = useSessionStorage<string>('programProduct', '')
  const term = useSessionStorage<DealDtoPolicyTermEnum>('term', DealDtoPolicyTermEnum.Annual)
  const expectedPremium = useSessionStorage<number | undefined>('expectedPremium', undefined)
  const clientId = useSessionStorage<string>('clientId', '')
  const clientName = useSessionStorage<string>('clientName', '')
  const clientAddress = useSessionStorage<string>('clientAddress', '')
  const clientAddress2 = useSessionStorage<string>('clientAddress2', '')
  const clientCity = useSessionStorage<string>('clientCity', '')
  const clientState = useSessionStorage<string>('clientState', '')
  const clientZip = useSessionStorage<string>('clientZip', '')
  const description = useSessionStorage<string>('description', '')
  const programTemplateDetails: RemovableRef<ProgramTemplateListModel> = useSessionStorage<ProgramTemplateListModel>(
    'programTemplateDetails',
    {} as ProgramTemplateListModel
  )

  function initializeState() {
    id.value = deal.value?.id || ''
    name.value = deal.value?.name || ''
    contractor.value = ''
    effectiveStartDate.value = deal.value?.policyInfo?.effectiveStartDate || ''
    effectiveEndDate.value = deal.value?.policyInfo?.effectiveEndDate || ''
    projectName.value = deal.value?.projectInfo?.name || ''
    retailerName.value = deal.value?.retailerInfo?.name || ''
    retailerOrganizationId.value = deal.value?.retailerInfo?.organizationId || ''
    retailerEmailsToAssign.value = deal.value?.retailerInfo?.contactEmails || []
    address.value = deal.value?.projectInfo?.address?.address1 || ''
    city.value = deal.value?.projectInfo?.address?.city || ''
    state.value = deal.value?.projectInfo?.address?.state || ''
    zip.value = deal.value?.projectInfo?.address?.zipCode || ''
    lat.value = deal.value?.projectInfo?.address?.lat || ''
    lng.value = deal.value?.projectInfo?.address?.lng || ''
    userEmail.value = authStore.user?.email || ''
    userEmailsToAssign.value = []
    userGroupsToAssign.value = []
    assetProfile.value = deal.value?.config?.assetProfile || DealConfigDtoAssetProfileEnum.SingleAsset
    programTemplate.value = ''
    programName.value = ''
    programProduct.value = ''
    programTemplateDetails.value = null

    clientId.value = deal.value?.clientInfo?.id || ''
    clientName.value = deal.value?.clientInfo?.name || ''
    clientAddress.value = deal.value?.clientInfo?.address?.address1 || ''
    clientAddress2.value = deal.value?.clientInfo?.address?.address2 || ''
    clientCity.value = deal.value?.clientInfo?.address?.city || ''
    clientState.value = deal.value?.clientInfo?.address?.state || ''
    clientZip.value = deal.value?.clientInfo?.address?.zipCode || ''

    initialized.value = true

    // Ok to do this async
    programStore.fetchProducts()
  }

  function clearState() {
    deal.value = null
    message.value = null

    id.value = null
    name.value = null
    contractor.value = null
    effectiveStartDate.value = null
    effectiveEndDate.value = null
    projectName.value = null
    retailerName.value = null
    retailerOrganizationId.value = null
    retailerEmailsToAssign.value = null
    address.value = null
    city.value = null
    state.value = null
    zip.value = null
    lat.value = null
    lng.value = null
    userEmail.value = null
    userEmailsToAssign.value = null
    userGroupsToAssign.value = null
    assetProfile.value = null
    programTemplate.value = null
    programTemplateDetails.value = null
    programName.value = null
    programProduct.value = null
    vertical.value = null
    term.value = null
    expectedPremium.value = null
    clientId.value = null
    clientName.value = null
    clientAddress.value = null
    clientAddress2.value = null
    clientCity.value = null
    clientState.value = null
    clientZip.value = null
    description.value = null

    initialized.value = false
    dataUpdated.value = false
  }

  function getDealInfoState(): InfoFormStateType {
    return {
      vertical: vertical.value,
      contractor: contractor.value,
      assetProfile: assetProfile.value as DealConfigDtoAssetProfileEnum,
      effectiveStartDate: effectiveStartDate.value,
      effectiveEndDate: effectiveEndDate.value,
      term: term.value,
      expectedPremium: expectedPremium.value,
      clientId: clientId.value,
      clientName: clientName.value,
      clientAddress: clientAddress.value,
      clientAddress2: clientAddress2.value,
      clientCity: clientCity.value,
      clientState: clientState.value,
      clientZip: clientZip.value,
      userGroupsToAssign: userGroupsToAssign.value,
      userEmailsToAssign: userEmailsToAssign.value,
      description: description.value,
      projectName: projectName.value
    }
  }

  const updateDealInfoState = async (payload: InfoFormStateType) => {
    vertical.value = payload.vertical
    contractor.value = payload.contractor
    assetProfile.value = payload.assetProfile
    effectiveStartDate.value = payload.effectiveStartDate
    effectiveEndDate.value = payload.effectiveEndDate
    term.value = payload.term
    expectedPremium.value = payload.expectedPremium
    clientId.value = payload.clientId
    clientName.value = payload.clientName
    clientAddress.value = payload.clientAddress
    clientAddress2.value = payload.clientAddress2
    clientCity.value = payload.clientCity
    clientState.value = payload.clientState
    clientZip.value = payload.clientZip
    userGroupsToAssign.value = payload.userGroupsToAssign
    userEmailsToAssign.value = payload.userEmailsToAssign
    description.value = payload.description
    if (!name.value) {
      name.value = await generatePlaceholderDealName()
    }

    dataUpdated.value = true
  }

  function getDealLocationState() {
    return {
      address: address.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      lat: lat.value,
      lng: lng.value
    }
  }

  function updateDealLocationState(payload: LocationFormStateType) {
    address.value = payload.address
    city.value = payload.city
    state.value = payload.state?.Code || ''
    zip.value = payload.zip
    lat.value = payload.lat
    lng.value = payload.lng

    dataUpdated.value = true
  }

  function getDealRetailerState(): RetailerFormStateType {
    return {
      retailerName: retailerName.value,
      retailerOrganizationId: retailerOrganizationId.value,
      retailerEmailsToAssign: retailerEmailsToAssign.value
    }
  }

  function updateDealRetailerState(payload: RetailerFormStateType) {
    retailerName.value = payload.retailerName
    retailerOrganizationId.value = payload.retailerOrganizationId
    retailerEmailsToAssign.value = payload.retailerEmailsToAssign

    dataUpdated.value = true
  }

  function getDealProgramState(): ProgramFormStateType {
    return {
      programProduct: programProduct.value,
      programName: programName.value,
      programTemplate: programTemplate.value
    }
  }

  function updateDealProgramState(payload: ProgramFormStateType) {
    programProduct.value = payload.programProduct
    programName.value = payload.programName
    programTemplate.value = payload.programTemplate
    programTemplateDetails.value = programStore.programTemplates.find((e) => e.id === payload.programTemplate)

    dataUpdated.value = true
  }

  function getDealReviewState(): ReviewFormStateType {
    return {
      name: name.value,
      description: description.value
    }
  }

  function updateDealReviewState(payload: ReviewFormStateType) {
    name.value = payload.name
    description.value = payload.description
    dataUpdated.value = true
  }

  async function assignUsers() {
    try {
      if ((userEmailsToAssign.value ?? []).length > 0) {
        const resp = await dealWriteService.shareDealBatch({
          dealId: deal.value?.id || '',
          batchShareDealRequest: {
            requests: userEmailsToAssign.value.map((u) => ({ email: u.email || '', ccEmails: [] })),
            notify: true
          }
        })
      }
      if ((userGroupsToAssign.value ?? []).length > 0) {
        const resp = await dealWriteService.shareDealTeams({
          dealId: deal.value?.id || '',
          request: {
            userGroupIds: userGroupsToAssign.value
          }
        })
      }
    } catch (err) {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    }
  }

  async function createProgram(dealId: string) {
    let programId: string | undefined
    try {
      if (programTemplate.value) {
        if (!programStore.programTemplates.length) {
          await programStore.fetchProgramTemplates()
        }
        programId = await programStore.createProgramFromTemplate({
          dealId,
          selectedTemplate: programStore.programTemplates.find((e) => e.id === programTemplate.value)!,
          newProgramName: programName.value
        })
      } else if (programName.value && programProduct.value) {
        const { id } = await programStore.createProgram({
          title: programName.value,
          product: programProduct.value as ProgramDtoProductEnum,
          dealId
        })
        if (id) {
          programId = id
        }
      }
    } catch (err) {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    }

    return programId
  }

  const generatePlaceholderDealName = async () =>
    await dealReadService.getUniqueDealName({
      clientName: clientName.value,
      projectName: projectName.value,
      state: state.value,
      zipCode: zip.value
    })

  async function createDealFromQueue() {
    let serverDealId = ''
    const updateDealPayload = {
      ...deal.value,
      name: name.value,
      displayName: name.value,
      verticals: [vertical.value as DealDtoVerticalsEnum],
      clientInfo: {
        id: clientId.value === 'created' ? undefined : clientId.value,
        name: clientName.value,
        address: {
          address1: clientAddress.value,
          address2: clientAddress2.value,
          city: clientCity.value,
          state: clientState.value,
          zipCode: clientZip.value
        }
      },
      policyInfo: {
        effectiveStartDate: effectiveStartDate.value,
        effectiveEndDate: effectiveEndDate.value,
        policyTerm: term.value
      },
      projectedPremium: expectedPremium.value,
      projectInfo: {
        name: projectName.value || name.value,
        address: {
          address1: address.value,
          city: city.value,
          state: state.value,
          zipCode: zip.value,
          lat: lat.value,
          lng: lng.value
        },
        contractor: contractor.value
      },
      retailerOrganizationId: retailerOrganizationId.value,
      retailerInfo: {
        name: retailerName.value,
        organizationId: retailerOrganizationId.value,
        contactEmails: retailerEmailsToAssign.value
      },
      config: {
        assetProfile: assetProfile.value
      },
      description: description.value,
      status: DealDtoStatusEnum.Active
    }
    try {
      const dealResponse = await dealWriteService.update(updateDealPayload)
      serverDealId = dealResponse?.id || ''
      assignUsers()
    } catch (err) {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    }
    return serverDealId
  }

  async function createNewDeal() {
    let serverDealId = ''
    const newDealPayload = {
      id: id.value,
      name: name.value,
      description: description.value,
      verticals: [vertical.value as DealDtoVerticalsEnum],
      generalContractorName: contractor.value,
      clientInfo: {
        id: clientId.value === 'created' ? undefined : clientId.value,
        name: clientName.value,
        address: {
          address1: clientAddress.value,
          address2: clientAddress2.value,
          city: clientCity.value,
          state: clientState.value,
          zipCode: clientZip.value
        }
      },
      policyTerm: term.value,
      effectiveStartDate: effectiveStartDate.value,
      effectiveEndDate: effectiveEndDate.value,
      projectedPremium: expectedPremium.value,
      // API requires projectName to be passed in, so let's use Deal name until someone complains
      projectName: projectName.value || name.value,
      retailerName: retailerName.value,
      retailerOrganizationId: retailerOrganizationId.value,
      retailerEmailsToAssign: retailerEmailsToAssign.value,
      address: address.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      lat: lat.value,
      lng: lng.value,
      userEmail: userEmail.value || authStore.user?.email,
      userEmailsToAssign: userEmailsToAssign.value.map((user) => user.email || ''),
      userGroupsToAssign: userGroupsToAssign.value,
      config: {
        assetProfile: assetProfile.value
      }
    }
    try {
      const response = await dealWriteService.createDeal(newDealPayload)
      serverDealId = response.deal?.id || ''
    } catch (err) {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    }
    return serverDealId
  }

  async function submitDeal() {
    const dealId = existingDealId.value ? await createDealFromQueue() : await createNewDeal()
    if (dealId) {
      notificationStore.publishSuccessMessage('Deal created successfully!')
      const programId = await createProgram(dealId || '')
      clearState()
      if (programId) {
        await router.push({ name: 'ProgramStructure', params: { dealId, programId } })
      } else {
        await router.push({ name: 'DealDetailView', params: { dealId } })
      }
    }
  }

  return {
    steps,
    validatedSteps,
    getNextValidStep,
    getPreviousValidStep,
    markStepValidated,
    markStepNotValidated,
    isStepValid,
    clearValidatedSteps,
    deal,
    loadDeal,
    message,
    loadMessage,
    id,
    name,
    clientName,
    effectiveStartDate,
    effectiveEndDate,
    vertical,
    projectName,
    retailerName,
    retailerOrganizationId,
    retailerEmailsToAssign,
    address,
    city,
    state,
    zip,
    lat,
    lng,
    userEmail,
    userEmailsToAssign,
    userGroupsToAssign,
    assetProfile,
    programTemplate,
    programTemplateDetails,
    programName,
    programProduct,
    initializeState,
    initialized,
    dataUpdated,
    clearState,
    getDealInfoState,
    updateDealInfoState,
    getDealLocationState,
    updateDealLocationState,
    getDealRetailerState,
    updateDealRetailerState,
    getDealProgramState,
    updateDealProgramState,
    isStepRequired,
    canNavigateToStep,
    getDealReviewState,
    updateDealReviewState,
    generatePlaceholderDealName,
    submitDeal
  }
})
