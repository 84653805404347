<script lang="ts">
import type { ProgramModel } from '@/capability/program/types'

export interface Props {
  program: ProgramModel
}

export interface Emits {
  (e: 'close'): void
  (e: 'keepOpen', payload: boolean): void
}
</script>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { z } from 'zod'

import { useZod } from '@/composables/zod'
import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { DropdownMenuItem } from '@/component/arqu-components/shadcn/ui/dropdown-menu'

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const programStore = useProgramStore()
const notificationStore = useNotificationStore()

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

const stateObject = { name: props.program!.title ?? '' }
const schemaObject = {
  name: z.string().min(1, 'Name is required')
}

const state = ref<typeof stateObject>(Object.assign({}, stateObject))

const { errors, resetErrors, resetState, validate } = useZod<typeof stateObject>({
  state,
  stateObject,
  schemaObject
})

function closeDialog() {
  dialog.value = false
}

async function submit() {
  try {
    resetErrors()
    loading.value = true
    const valid = validate()
    if (valid) {
      await programStore.copyProgram(props.program!.id as string, state.value.name)
      notificationStore.publishSuccessMessage('Program copied')
      closeDialog()
    }
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  } finally {
    loading.value = false
  }
}

watch(dialog, (value) => {
  if (value) {
    stateObject.name = props.program!.title ?? ''
    resetState()
    resetErrors()
    emit('keepOpen', true)
  } else {
    emit('close')
  }
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <DropdownMenuItem @select.prevent="">Copy</DropdownMenuItem>
    </DialogTrigger>
    <DialogContent class="min-w-[40vw]">
      <form @submit.prevent="submit">
        <DialogHeader class="mb-2 border-b">
          <DialogTitle>Copy Program</DialogTitle>
          <DialogDescription>Create a copy of this program with a new name.</DialogDescription>
        </DialogHeader>
        <rq-text-field v-model="state.name" id="programCopyName" :autofocus="true" :errors="errors.name" label="Name" />
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <rq-btn id="cancel-btn" :disabled="loading" type="button" variant="outline" @click="dialog = false">Cancel</rq-btn>
            <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" :loading type="submit" variant="primary">Save</rq-btn>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
