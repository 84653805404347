import type { LineOfBusinessModel } from '@/capability/line-of-business/types'
import { apiConfiguration } from '@/capability/system/config'
import { LineOfBusinessApi } from 'typescript-core-api-client'

export interface LineOfBusinessService {
  getAll: () => Promise<LineOfBusinessModel[]>
}

export const lineOfBusinessService = {
  getAll: async function (): Promise<LineOfBusinessModel[]> {
    const config = await apiConfiguration()
    const lineOfBusinessApi = new LineOfBusinessApi(config)
    const response = await lineOfBusinessApi.getAll({ headers: { 'Cache-Control': 'max-age=86400' } })
    return (response.data?.lineOfBusinesses as LineOfBusinessModel[]) || []
  }
} satisfies LineOfBusinessService
