import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw = {
  path: '/business-intelligence',
  children: [
    {
      path: '',
      name: 'BusinessIntelligenceBase',
      redirect: { path: '/' }
    },
    {
      path: 'carrier-engagement',
      name: 'BusinessIntelligenceCarrierEngagement',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceCarrierEngagement.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-carrier-engagement',
          resourceType: 'business-intelligence-carrier-engagement',
          resourceCrudl: 'read'
        },
        title: 'Carrier Engagement'
      }
    },
    {
      path: 'kpi',
      name: 'BusinessIntelligenceKPI',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceKPI.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-kpi',
          resourceType: 'business-intelligence-kpi',
          resourceCrudl: 'read'
        },
        title: 'KPI'
      }
    },
    {
      path: 'conversion',
      name: 'BusinessIntelligenceConversion',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceConversion.vue'),
      meta: {
        requriesAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-conversion',
          resourceType: 'business-intelligence-conversion',
          resourceCrudl: 'read'
        },
        title: 'Conversion'
      }
    },
    {
      path: 'retailer-engagement',
      name: 'BusinessIntelligenceRetailerEngagement',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceRetailerEngagement.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-retailer-engagement',
          resourceType: 'business-intelligence-retailer-engagement',
          resourceCrudl: 'read'
        },
        title: 'Retailer Engagement'
      }
    },
    {
      path: 'underwriter-backlog',
      name: 'BusinessIntelligenceUnderwriterBacklog',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceUnderwriterBacklog.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-underwriter-backlog',
          resourceType: 'business-intelligence-underwriter-backlog',
          resourceCrudl: 'read'
        },
        title: 'Underwriter Backlog'
      }
    },
    {
      path: 'ai-utilization',
      name: 'BusinessIntelligenceAIUtilization',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceAIUtilization.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-ai-utilization',
          resourceType: 'business-intelligence-ai-utilization',
          resourceCrudl: 'read'
        },
        title: 'AI Utilization'
      }
    },
    {
      path: 'service-levels',
      name: 'BusinessIntelligenceServiceLevels',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceServiceLevels.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-service-levels',
          resourceType: 'business-intelligence-service-levels',
          resourceCrudl: 'read'
        },
        title: 'Service Levels'
      }
    },
    {
      path: 'user-activity',
      name: 'BusinessIntelligenceUserActivity',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceUserActivity.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-userActivity',
          resourceType: 'business-intelligence-userActivity',
          resourceCrudl: 'read'
        },
        title: 'User Activity'
      }
    },
    {
      path: 'rate-history',
      alias: 'Rate%20History',
      name: 'BusinessIntelligenceRateHistory',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceRateHistory.vue'),
      meta: {
        title: 'Rate History',
        pageViewDetails: {
          pageId: 'business-intelligence-rate-history',
          resourceType: 'business-intelligence-rate-history',
          resourceCrudl: 'read'
        },
        requiresAuth: true
      }
    },
    {
      path: 'helpdesk-reports',
      name: 'BusinessIntelligenceHelpdeskReports',
      component: () => import('@/views/business-intelligence/BusinessIntelligenceHelpdeskReports.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'business-intelligence-helpdesk-reports',
          resourceType: 'business-intelligence-helpdesk-reports',
          resourceCrudl: 'read'
        },
        title: 'Helpdesk Reports'
      }
    }
  ]
}

export default routes
