<script setup lang="ts">
import type { PropType } from 'vue'
import { onMounted } from 'vue'
import { ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import { productsService } from '@/capability/products/service'
import type { ProgramModel } from '@/capability/program/types'
import type { ProgramDtoProductEnum } from 'typescript-core-api-client'
import type { ProgramDtoStatusEnum } from 'typescript-core-api-client/dist/api'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { DropdownMenuItem } from '@/component/arqu-components/shadcn/ui/dropdown-menu'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'keepOpen', payload: boolean): void
}>()

const programStore = useProgramStore()
const notificationStore = useNotificationStore()

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)
const productsLoading = ref<boolean>(false)
const status = ref<ProgramDtoStatusEnum>(props.program!.status as ProgramDtoStatusEnum)
const visibility = ref<'Visible' | 'Hidden'>(props.program!.visible ? 'Visible' : 'Hidden')
const product = ref<ProgramDtoProductEnum>(props.program!.product as ProgramDtoProductEnum)

const productTypes = ref<{ value?: string; label: string }[]>([])

onMounted(() => {
  productsLoading.value = true
  productsService
    .getProducts()
    .then((res) => {
      productTypes.value = res.map(({ name, displayName, category }) => ({
        value: name,
        label: `${category} - ${displayName}`
      }))
    })
    .catch((err) => {
      notificationStore.publishOneOrMoreErrUnhandled(err)
      productTypes.value = []
    })
    .finally(async () => {
      productsLoading.value = false
    })
})

async function submit() {
  try {
    loading.value = true
    const _program: ProgramModel = cloneDeep(props.program) as ProgramModel
    _program.status = status.value
    _program.visible = visibility.value === 'Visible'
    _program.product = product.value
    await programStore.editProgram(_program)
    notificationStore.publishSuccessMessage('Program updated successfully')
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  } finally {
    closeDialog()
    loading.value = false
  }
}

function closeDialog() {
  dialog.value = false
}

watch(status, (value) => {
  if (value === 'Active') {
    visibility.value = 'Visible'
  }
})

watch(dialog, (value) => {
  if (value) {
    emit('keepOpen', true)
  } else {
    emit('close')
  }
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child :disabled="productsLoading">
      <DropdownMenuItem @select.prevent="">Select Status and Product</DropdownMenuItem>
    </DialogTrigger>
    <DialogContent class="min-w-[40vw]">
      <form @submit.prevent="submit">
        <DialogHeader class="mb-2 border-b">
          <DialogTitle>Edit Program</DialogTitle>
          <DialogDescription>Change the status, visibility, and product of this program.</DialogDescription>
        </DialogHeader>
        <rq-listbox-single v-model="status" :items="['Active', 'Inactive']" label="Program Status" />
        <rq-listbox-single v-model="visibility" :disabled="status === 'Active'" :items="['Visible', 'Hidden']" label="Program Visibility" />
        <rq-listbox-single v-model="product" :items="productTypes" label="Product Type" text-field="label" value-field="value" />
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <rq-btn
              id="cancel-btn"
              :disabled="loading"
              :style="{ border: '1px solid rgb(229 231 235) !important' }"
              type="button"
              variant="outline"
              @click="dialog = false"
            >
              Cancel
            </rq-btn>
            <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" :loading="loading" type="submit" variant="primary">Save</rq-btn>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>
