import type { ClearingModel } from '@/capability/clearing/types'
import { apiConfiguration } from '@/capability/system/config'
import type {
  CreateClearingRequest,
  DeleteClearingRequest,
  SendClearingsRequest,
  SendClearingsResponse,
  UpdateClearingRequest,
  UpdateClearingResponse
} from 'typescript-core-api-client'
import { ClearingApi } from 'typescript-core-api-client'
import type { CreateClearingResponse, DeleteClearingResponse } from 'typescript-core-api-client/dist/api'

export type GetClearingsMode = 'Unknown' | 'Full' | 'Summary'

export type GetByDealIdPayloadType = {
  dealId: string
  mode?: GetClearingsMode
}

export interface ClearingService {
  getByDealId: (payload: GetByDealIdPayloadType) => Promise<ClearingModel[]>
  createClearing: (clearing: CreateClearingRequest) => Promise<CreateClearingResponse>
  updateClearing: (payload: UpdateClearingRequest) => Promise<UpdateClearingResponse>
  sendClearings: (payload: SendClearingsRequest) => Promise<SendClearingsResponse>
  deleteClearings: (payload: DeleteClearingRequest) => Promise<DeleteClearingResponse>
}

export const clearingService = {
  getByDealId: async function (payload: GetByDealIdPayloadType): Promise<ClearingModel[]> {
    const config = await apiConfiguration()
    const clearingApi = new ClearingApi(config)

    const { dealId, mode } = payload
    const response = await clearingApi.getByDeal(dealId, mode)
    return response.data.clearings || []
  },
  createClearing: async function (clearing: CreateClearingRequest): Promise<CreateClearingResponse> {
    const config = await apiConfiguration()
    const clearingApi = new ClearingApi(config)

    const response = await clearingApi.create(clearing)
    return response.data
  },
  updateClearing: async function (payload: UpdateClearingRequest): Promise<UpdateClearingResponse> {
    const config = await apiConfiguration()
    const clearingApi = new ClearingApi(config)

    const response = await clearingApi.update(payload)
    return response.data
  },
  sendClearings: async function (payload: SendClearingsRequest): Promise<SendClearingsResponse> {
    const config = await apiConfiguration()
    const clearingApi = new ClearingApi(config)

    const response = await clearingApi.sendClearings(payload)
    return response.data
  },
  deleteClearings: async function (payload: DeleteClearingRequest): Promise<DeleteClearingResponse> {
    const config = await apiConfiguration()
    const clearingApi = new ClearingApi(config)

    const response = await clearingApi._delete(payload)
    return response.data
  }
} satisfies ClearingService
