<script lang="ts">
import type { DealModel } from '@/capability/deal/types'
import type { ProgramModel } from '@/capability/program/types'

export type Props = {
  programModel: ProgramModel
  deal: DealModel
}
</script>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useProgramStore } from '@/stores/program'

import type { ExposureModel } from '@/capability/exposure/types'

import { MessagesWrapper } from '@/component/messages/'
import ProgramSchematicComponent from '@/component/program/ProgramSchematicComponent.vue'

const programStore = useProgramStore()
const route = useRoute()

const isLoading = ref(false)

const props = defineProps<Props>()

const displayProgramSchematic = ref<boolean>(true)
const headerText = computed(() => (displayProgramSchematic.value ? 'Schematic' : 'Messages'))
const icon = computed(() => (displayProgramSchematic.value ? 'mdi:email-open' : 'mdi:chart-box'))
const tooltipText = computed(() => (displayProgramSchematic.value ? 'Messages' : 'Schematic'))
const exposuresModel = defineModel('exposuresModel', { type: Array as PropType<ExposureModel[]>, required: true })

// Methods
function handleClick() {
  programStore.visibleSideBar = programStore.visibleSideBar === 'both' ? 'left' : 'both'
}
</script>

<template>
  <rq-loading-overlay v-model="isLoading" />
  <div class="sticky top-[128px] rounded-lg border">
    <div class="h-[30px] w-full bg-section-header py-0 pl-5 pr-0">
      <div class="grid grid-cols-6 text-center text-sm">
        <span class="col-span-2">
          <rq-btn
            v-if="!displayProgramSchematic"
            class="hover:bg-gray-200"
            size="sm"
            variant="ghost"
            @click="displayProgramSchematic = true"
          >
            <rq-icon class="mr-2" color="gray-500" icon="mdi:arrow-left" />
            Schematics
          </rq-btn>
        </span>
        <span class="col-span-3 my-auto font-medium text-rq-black">{{ headerText }}</span>
        <span class="col-span-1 flex items-center justify-end">
          <rq-btn
            id="program-schematic-toggle-messages-schematic-button"
            icon="square"
            size="xs"
            variant="ghost-primary"
            @click="displayProgramSchematic = !displayProgramSchematic"
          >
            <rq-icon color="primary" :icon="icon" />
            <rq-tooltip :text="tooltipText" />
          </rq-btn>
          <rq-btn
            id="program-schematic-expand-button"
            class="hidden pr-1 pt-1 hover:bg-gray-200 md:inline"
            icon="square"
            size="xs"
            variant="ghost"
            @click="handleClick"
          >
            <rq-icon :icon="programStore.getIcon" />
            <rq-tooltip text="Toggle Full Width" />
          </rq-btn>
        </span>
      </div>
    </div>
    <div>
      <ProgramSchematicComponent
        v-show="displayProgramSchematic"
        v-model:exposures-model="exposuresModel"
        :program-model="programModel as ProgramModel"
        :show-add-tower-button="route.meta.programShowAddTower"
        :show-assign-market-button="route.meta.programShowAssignMarket"
      />
      <MessagesWrapper v-if="!displayProgramSchematic" class="max-h-[65vh]" :deal-id="deal.id" />
    </div>
    <!--      <ProgramMessageList v-else :messages="messages" />-->
  </div>
</template>
