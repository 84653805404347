import { apiConfiguration } from '@/capability/system/config'
import { type AddMessageTagResponse, MessageApi, type RemoveMessageTagResponse } from 'typescript-core-api-client'
import type { GetMessageResponse } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type GetMessagePayloadType = {
  messageId: string
}

export type AddTagPayloadType = {
  messageId: string
  tag: string
}

export type RemoveTagPayloadType = {
  messageId: string
  tag: string
}

export interface MessagesService {
  getMessage: (payload: GetMessagePayloadType) => Promise<GetMessageResponse>
  addTag: (payload: AddTagPayloadType) => Promise<AddMessageTagResponse>
  removeTag: (payload: RemoveTagPayloadType) => Promise<RemoveMessageTagResponse>
}

export const messagesService = {
  getMessage: async function ({ messageId }: GetMessagePayloadType): Promise<GetMessageResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const messageApi = new MessageApi(config)

    try {
      const response = await messageApi.getMessageById(messageId)
      return response.data
    } catch (e) {
      if ((e as unknown as any).response?.status === 404) {
        return { message: undefined } as GetMessageResponse
      }
      throw e
    }
  },
  addTag: async function (payload: AddTagPayloadType): Promise<AddMessageTagResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const messageApi = new MessageApi(config)

    const { messageId, tag } = payload
    const response = await messageApi.addTag(messageId, tag)
    return response.data
  },
  removeTag: async function (payload: RemoveTagPayloadType): Promise<RemoveMessageTagResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const messageApi = new MessageApi(config)

    const { messageId, tag } = payload
    const response = await messageApi.removeTag(messageId, tag)
    return response.data
  }
} satisfies MessagesService
