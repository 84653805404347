import { useAcl } from 'vue-simple-acl/src'

import { apiLocationOrigin } from '@/lib/config'

import type { DealModelWithMemo, DealStatus } from '@/capability/deal/types'
import type { DealModel } from '@/capability/deal/types/deal-model'
import { apiConfiguration } from '@/capability/system/config'
import type {
  DealMinimalDto,
  DocumentDto,
  GetMinimalDealsByScoreResponse,
  HeadlineMetrics,
  ListByTypeFilterEnum,
  ListByTypeVerticalEnum,
  ListDocumentsResponse,
  MemoDto,
  UpdateDealCalendarEventRequest,
  UpdateDealFromDocumentAnnotationRequest,
  UserDto
} from 'typescript-core-api-client'
import { GoogleCalendarControllerApi, ListByScoreDealRankTypeEnum } from 'typescript-core-api-client'
import { DealApi } from 'typescript-core-api-client'
import type { CreateDealCalendarEventRequest, ListByTypeRoleEnum, ListByTypeSortEnum } from 'typescript-core-api-client/dist/api'
import type { ContractorCheckResultDto, GoogleCalendarEventDto } from 'typescript-core-api-client/dist/api'
import type { GetDealStatisticsResponse } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type SortByType = 'Client' | 'CreatedAt' | 'ModifiedAt' | 'Name' | 'Owner'
export type OrderByType = 'Asc' | 'Desc'

export type ListDealsReturnType = {
  totalCount: number
  deals: DealModelWithMemo[]
}

export type ListMinimalDealsReturnType = {
  totalCount: number
  deals: DealMinimalDto[]
}

type ListDealsPayloadType = {
  search?: string
  status?: DealStatus[]
  sortedBy?: SortByType
  sortOrder?: OrderByType
  pageNumber?: number
  pageSize?: number
  activity?: boolean
  activityResultSize?: number
  groupId?: string
}

type ListMinimalDealsPayloadType = ListDealsPayloadType & {
  memo?: boolean
}

export type UploadDocumentPayloadType = {
  dealId: string
  files: File[]
  documentName?: string
  documentType?: string
  policyId?: string
  policyName?: string
  validateCheckSum?: boolean
}

export type UploadFilesPayloadType = {
  dealId: string
  files: File[]
  paths: string[]
}

type GetDocumentsPayloadType = {
  dealId: string
}

type GetDealPrivilegesPayloadType = {
  dealId: string
}

type UpdateDealPayloadType = {
  deal: DealModel
}

type GetDealPrivilegeUsersPayloadType = {
  dealId: string
}

type SendAnnotationToDealPayloadType = {
  request: UpdateDealFromDocumentAnnotationRequest
}

type GetHeadlineMetricsPayloadType = {
  dealId: string
}

type GetDealEventsPayloadType = {
  dealId: string
}

export type CreateDealEventPayloadType = CreateDealCalendarEventRequest & {
  dealId: string
}

export type UpdateDealEventPayloadType = UpdateDealCalendarEventRequest & {
  dealId: string
  eventId: string
}

type GetContractorCheckResultsPayloadType = {
  dealId: string
}

export type GetDealStatisticsPayloadType = {
  filter?: ListByTypeFilterEnum
  getAll?: boolean
  interval?: string
  role?: ListByTypeRoleEnum
  vertical?: ListByTypeVerticalEnum
  size?: number
  sort?: ListByTypeSortEnum
}

export type GetDealScoresPayloadType = {
  lastId?: string
  size?: number
}

export interface Index {
  listDeals: (payload: ListDealsPayloadType) => Promise<ListDealsReturnType>
  listMinimalDeals: (payload: ListMinimalDealsPayloadType) => Promise<ListMinimalDealsReturnType>
  uploadDocument: (payload: UploadDocumentPayloadType) => Promise<DocumentDto[]>
  uploadFiles: (payload: UploadFilesPayloadType) => Promise<string>
  uploadDealImage: (payload: UploadFilesPayloadType) => Promise<string>
  uploadDealRiskConsideration: (payload: UploadFilesPayloadType) => Promise<string>
  getDocuments: (payload: GetDocumentsPayloadType) => Promise<ListDocumentsResponse>
  getDealPrivilegeUsers: (payload: GetDealPrivilegeUsersPayloadType) => Promise<UserDto[]>
  sendAnnotationToDeal: (payload: SendAnnotationToDealPayloadType) => Promise<void>
  getHeadlineMetrics: (payload: GetHeadlineMetricsPayloadType) => Promise<HeadlineMetrics>
  getDealEvents: (payload: GetDealEventsPayloadType) => Promise<GoogleCalendarEventDto[]>
  createDealEvent: (payload: CreateDealEventPayloadType) => Promise<GoogleCalendarEventDto[]>
  updateDealEvent: (payload: UpdateDealEventPayloadType) => Promise<GoogleCalendarEventDto[]>
  getContractorCheckResults: (payload: GetContractorCheckResultsPayloadType) => Promise<ContractorCheckResultDto[]>
  getDealStatistics: (payload: GetDealStatisticsPayloadType) => Promise<GetDealStatisticsResponse>
  getDealScores: (payload: GetDealScoresPayloadType) => Promise<GetMinimalDealsByScoreResponse>
}

export const dealService = {
  listDeals: async function (payload: ListDealsPayloadType): Promise<ListDealsReturnType> {
    const { search, status, sortedBy, sortOrder, groupId, pageNumber, pageSize } = payload
    const acl = useAcl()
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)
    const response = await dealApi.list(groupId, true, pageNumber, pageSize, search, sortedBy, sortOrder, status)
    const memos: Map<string, MemoDto> = new Map()
    if (response.data.memos) {
      for (const memo of response.data.memos) {
        if (memo && memo.dealId) {
          memos.set(memo.dealId, memo)
        }
      }
    }

    return {
      totalCount: response.data.totalCount ?? 0,
      deals: (response.data.deals ?? []).map((deal) => {
        return { ...deal, memo: memos.get(deal.id!) }
      })
    }
  },
  listMinimalDeals: async function (payload: ListMinimalDealsPayloadType): Promise<ListMinimalDealsReturnType> {
    const { activity, activityResultSize, memo, search, status, sortedBy, sortOrder, pageNumber, pageSize, groupId } = payload
    const acl = useAcl()
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)
    const response = await dealApi.listMinimal(
      activity,
      activityResultSize,
      groupId,
      memo,
      pageNumber,
      pageSize,
      search,
      sortedBy,
      sortOrder,
      status
    )

    const memos: Map<string, MemoDto> = new Map()
    if (response.data.memos) {
      for (const memo of response.data.memos) {
        if (memo && memo.dealId) {
          memos.set(memo.dealId, memo)
        }
      }
    }

    return {
      totalCount: response.data.totalCount ?? 0,
      deals: (response.data.deals ?? []).map((deal) => {
        return { ...deal, memo: memos.get(deal.id!) }
      })
    }
  },
  uploadDocument: async function (payload: UploadDocumentPayloadType): Promise<DocumentDto[]> {
    const { dealId, files, documentName, documentType, policyId, policyName, validateCheckSum } = payload
    const config: CoreApiConfiguration = await apiConfiguration()

    const formData = new FormData()
    for (const file of files) {
      formData.append('file', file)
    }

    if (documentName) {
      formData.append('documentName', documentName)
    }

    if (documentType) {
      formData.append('documentType', documentType)
    }

    if (policyId) {
      formData.append('policyId', policyId)
    }

    if (policyName) {
      formData.append('policyName', policyName)
    }

    formData.append('validateChecksum', validateCheckSum ? 'true' : 'false')

    const response = await fetch(`${apiLocationOrigin}/v1/deals/${dealId}/documents`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
        'X-Arqu-Session-Id': sessionStorage.getItem('rq-session') || '',
        Accept: 'application/json, text/plain, */*'
      },
      body: formData
    })

    if (!response.ok) {
      throw new Error(`Upload failed: ${response.status} ${response.statusText}`)
    }

    const data: { documents: DocumentDto[]; document: DocumentDto } = await response.json()
    if (!data.documents) return [data.document!]
    return data.documents
  },
  uploadFiles: async function (payload: UploadFilesPayloadType): Promise<string> {
    const { dealId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()

    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    for (const path of paths) {
      formData.append('filePaths', path)
    }

    const response = await fetch(`${apiLocationOrigin}/v1/deals/${dealId}/files/rn`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
        'X-Arqu-Session-Id': sessionStorage.getItem('rq-session') || '',
        Accept: 'application/json, text/plain, */*'
      },
      body: formData
    })

    if (!response.ok) {
      throw new Error('Failed to upload files')
    }

    const data: { riskNarrativeURL: string } = await response.json()
    return data.riskNarrativeURL ?? ''
  },
  uploadDealImage: async function (payload: UploadFilesPayloadType): Promise<string> {
    const { dealId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()

    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    for (const path of paths) {
      formData.append('filePaths', path)
    }

    const response = await fetch(`${apiLocationOrigin}/v1/deals/${dealId}/files/deal-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
        'X-Arqu-Session-Id': sessionStorage.getItem('rq-session') || '',
        Accept: 'application/json, text/plain, */*'
      },
      body: formData
    })

    if (!response.ok) {
      throw new Error('Failed to upload deal image')
    }

    const data: { dealImageLocation: string } = await response.json()
    return data.dealImageLocation ?? ''
  },
  uploadDealRiskConsideration: async function (payload: UploadFilesPayloadType): Promise<string> {
    const { dealId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()

    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    for (const path of paths) {
      formData.append('filePaths', path)
    }

    const response = await fetch(`${apiLocationOrigin}/v1/deals/${dealId}/files/risk-consideration`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
        'X-Arqu-Session-Id': sessionStorage.getItem('rq-session') || '',
        Accept: 'application/json, text/plain, */*'
      },
      body: formData
    })

    if (!response.ok) {
      throw new Error('Failed to upload deal risk consideration')
    }

    const data: { dealRiskConsiderationLocation: string } = await response.json()
    return data.dealRiskConsiderationLocation ?? ''
  },
  getDocuments: async function (payload: GetDocumentsPayloadType): Promise<ListDocumentsResponse> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listDocuments(dealId)
    return response.data
  },
  getDealPrivilegeUsers: async function (payload: GetDealPrivilegeUsersPayloadType): Promise<UserDto[]> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listPrivilegeUsers(dealId)
    return response.data.users ?? []
  },
  sendAnnotationToDeal: async function (payload: SendAnnotationToDealPayloadType): Promise<void> {
    const { request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    await dealApi.updateDealFromDocumentAnnotation(request)
    return Promise.resolve()
  },
  getHeadlineMetrics: async function (payload: GetHeadlineMetricsPayloadType): Promise<HeadlineMetrics> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.getHeadlineMetrics(dealId)
    return response.data.headlineMetrics ?? {}
  },
  getDealEvents: async function (payload: GetDealEventsPayloadType): Promise<GoogleCalendarEventDto[]> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const googleCalendarControllerApi = new GoogleCalendarControllerApi(config)

    const response = await googleCalendarControllerApi.getEvents(dealId)
    return response.data?.events ?? []
  },
  createDealEvent: async function (payload: CreateDealEventPayloadType): Promise<GoogleCalendarEventDto[]> {
    const { dealId, ...rest } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const googleCalendarControllerApi = new GoogleCalendarControllerApi(config)

    const response = await googleCalendarControllerApi.createEvent(dealId, rest as CreateDealCalendarEventRequest)
    return response.data?.events ?? []
  },
  updateDealEvent: async function (payload: UpdateDealEventPayloadType): Promise<GoogleCalendarEventDto[]> {
    const { dealId, eventId, ...rest } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const googleCalendarControllerApi = new GoogleCalendarControllerApi(config)

    const response = await googleCalendarControllerApi.updateEvent(dealId, eventId, { ...rest, eventId } as UpdateDealCalendarEventRequest)
    return response.data?.events ?? []
  },
  getContractorCheckResults: async function (payload: GetContractorCheckResultsPayloadType): Promise<ContractorCheckResultDto[]> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.getContractorCheckResults(dealId)
    return response.data.results ?? []
  },
  getDealStatistics: async function ({
    filter,
    getAll,
    interval,
    role,
    vertical,
    size,
    sort
  }: GetDealStatisticsPayloadType): Promise<GetDealStatisticsResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listByType(filter, getAll, interval, role, size, sort, vertical)
    return response.data
  },
  getDealScores: async function ({ lastId, size }: GetDealScoresPayloadType) {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listByScore(ListByScoreDealRankTypeEnum.PriorityBased, lastId, size)
    return response.data
  }
} satisfies Index
