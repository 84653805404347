import cloneDeep from 'lodash.clonedeep'
import sortBy from 'lodash.sortby'

import type { LayerModel } from '@/capability/layer/types'
import {
  getAllLayerLayers,
  getLayerExcess,
  getLayerLimit,
  getNestedLayerById,
  setLayerExcess,
  setLayerLimit
} from '@/capability/layer/utils'
import type { TowerModel } from '@/capability/tower/types'
import { getTowerLimit } from '@/capability/tower/utils/index'

export const getLayersLimitSum = (layers: LayerModel[]): number =>
  layers
    .filter((layer) => !layer.plug)
    .map((layer) => getLayerLimit(layer) ?? 0)
    .reduce((a, b) => a + b, 0)

/**
 * This is the beginnings of dealing with Justin's naming logic mentioned here
 *
 * https://arqu-co.slack.com/archives/C02FTF1EC8K/p1654023198515659
 * https://arqu-co.slack.com/archives/C02FTF1EC8K/p1654093762315439
 *
 * Tim's comment is not yet accounted for
 *
 * https://arqu-co.slack.com/archives/C02FTF1EC8K/p1654095541240259
 *
 * That may be accounted for within this service.
 * Or within LayerProgrammaticLabelingServiceBuildersRiskImpl if that's necessary
 */
export const generateLabel = (layer: LayerModel): string => {
  if (layer.percentage != null) {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(layer.percentage / 100)
  } else {
    const limit = +getLayerLimit(layer)!
    const excess = +getLayerExcess(layer)!
    const formattedLimit = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      notation: 'compact'
    }).format(limit)
    const formattedExcess = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      notation: 'compact'
    }).format(excess)
    if (limit != null && limit > 0 && excess === 0) {
      return `P${formattedLimit}`
    } else if (limit != null && excess != null) {
      return `${formattedLimit}xs${formattedExcess}`
    } else {
      return ''
    }
  }
}

const getSortedLayers = (layers: LayerModel[], key: 'excess' | 'limit' = 'excess'): LayerModel[] =>
  sortBy(layers, (l) => {
    if (key === 'excess') {
      return getLayerExcess(l)
    } else {
      return getLayerLimit(l)
    }
  })

const getSortedNonPlugLayers = (layers: LayerModel[], key: 'excess' | 'limit' = 'excess'): LayerModel[] =>
  getSortedLayers(layers, key).filter((e) => !e.plug)

export const getMaxNonPlugLimit = (layers: LayerModel[], key: 'excess' | 'limit' = 'excess'): number => {
  const sortedLayers = getSortedNonPlugLayers(layers, key)
  const topMostNonPlugLayer = sortedLayers[sortedLayers.length - 1]
  return +getLayerExcess(topMostNonPlugLayer)! + +getLayerLimit(topMostNonPlugLayer)!
}

export const getTopMostLayer = (layers: LayerModel[]): LayerModel => {
  const sortedLayers = getSortedLayers(layers)
  return sortedLayers[sortedLayers.length - 1]
}

export const getLayersTotal = (layers: LayerModel[]): number => layers.map((l) => getLayerLimit(l) ?? 0).reduce((a, b) => a + b, 0)

export const setUpTopPlugLayer = (tower: TowerModel): TowerModel => {
  const towerClone = cloneDeep(tower)
  const topMostLayer = getTopMostLayer(towerClone.layers ?? [])
  if (getMaxNonPlugLimit(towerClone.layers ?? []) !== +getTowerLimit(towerClone)!) {
    if (topMostLayer.plug) {
      const layers = towerClone.layers || []
      setLayerLimit(layers[layers.length - 1], +getTowerLimit(towerClone)! - +getLayerExcess(topMostLayer)!)
      towerClone.layers = layers
    } else {
      const excess = +getLayerExcess(topMostLayer)! + +getLayerLimit(topMostLayer)!
      const layer: LayerModel = {
        name: undefined,
        plug: true
      } as LayerModel
      setLayerLimit(layer, +getTowerLimit(towerClone)! - excess)
      setLayerExcess(layer, excess)
      towerClone.layers = [...(towerClone.layers || []), layer]
    }
  } else {
    if (topMostLayer.plug) {
      towerClone.layers = towerClone.layers!.filter((e) => e.id !== topMostLayer.id)
    }
  }
  return towerClone
}

export function getNonPlugTowerLayers(tower: TowerModel): LayerModel[] {
  return (tower.layers ?? []).filter((l) => !l.plug)
}

export function getAllTowerLayers(tower?: TowerModel): LayerModel[] {
  if (!tower) return []
  const layers = cloneDeep(tower.layers || [])
  for (const layer of layers) {
    layers.push(...getAllLayerLayers(layer))
  }
  return layers
}

export type GetTowerLayerByIdPayloadType = {
  tower: TowerModel
  layerId: string
}

export function getTowerLayerById({ tower, layerId }: GetTowerLayerByIdPayloadType): LayerModel | undefined {
  if (!tower.layers) return undefined
  for (const layer of tower.layers) {
    if (layer.id === layerId) {
      return layer
    }
    const segment = getNestedLayerById({ layer, layerId })
    if (segment != null) {
      return segment
    }
  }
  return undefined
}

export default {
  getLayersLimitSum,
  generateLabel,
  getSortedLayers,
  getSortedNonPlugLayers,
  getMaxNonPlugLimit,
  getTopMostLayer,
  setUpTopPlugLayer
}
