import { computed, inject, ref } from 'vue'
import { useDebounce } from '@vueuse/core'

import { DialogConfirm } from '@/composables/dialog/dialogConfirm'

import type { ClearingModel } from '@/capability/clearing/types'
import { getDocumentName } from '@/capability/document/util'
import { emailService } from '@/capability/email/service'
import type { RenderTemplateType } from '@/capability/email/types'
import type { RenderTemplateResponse } from 'typescript-core-api-client'

export type HandleEditTemplateType = {
  draft: ClearingModel
  dealId: string
  marketId: string
  programId: string
}

export type HandleUpdatePreviewType = {
  dealId: string
  draft: ClearingModel
  marketId: string
  programId: string
}

export function useClearingSubmission() {
  const body = ref<string>('')
  const debouncedBody = useDebounce(body, 1000)
  const subject = ref<string>('')
  const dialog = ref<boolean>(false)
  const loading = ref<boolean>(false)
  const email = ref<RenderTemplateResponse>({})
  const emailBody = ref<string>('')
  const debouncedEmailBody = useDebounce(emailBody, 1000)
  const template = computed(() => email.value.content ?? '')
  const wholeTemplate = ref<boolean>(false)

  function resetContent() {
    body.value = ''
    subject.value = ''
    email.value = {}
    emailBody.value = ''
    wholeTemplate.value = false
  }

  const confirmDialog = inject(DialogConfirm)!

  async function handleEditTemplate(payload: HandleEditTemplateType) {
    const { draft, dealId, marketId, programId } = payload
    const customMessage = body.value || `<h1><strong>PLACEHOLDER CONTENT ONLY!</strong></h1>`
    await confirmDialog({
      title: 'Edit Entire Template',
      description:
        'Any data required to populate this template will become static. If the deal updates after editing the template you will need to re-create the draft to get the latest data.',
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: async () => {
        wholeTemplate.value = true
        email.value = await emailService.renderTemplate({
          id: draft.templateId,
          request: {
            dealId,
            customMessage,
            documentIds: draft.documentIds,
            marketId,
            programId,
            recipients: draft.primaryEmailAddresses
          }
        } as RenderTemplateType)
        emailBody.value = email.value.content as string
        if (!subject.value) {
          subject.value = email.value.subject as string
        }
      },
      cardClass: 'w-[95vw] sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw]',
      descriptionClass: 'text-start'
    })
    if (emailBody.value.includes('PLACEHOLDER CONTENT ONLY!')) {
      await confirmDialog({
        title: 'Notice',
        description:
          'There is placeholder information in the section that would normally contain the custom message. Please delete this section before sending if you do not intend on replacing this custom content.',
        okText: 'Close',
        cardClass: 'w-[95vw] sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw]',
        descriptionClass: 'text-start',
        displayCancel: false
      })
    }
  }

  async function handleUpdatePreview(payload: HandleUpdatePreviewType) {
    const { dealId, draft, marketId, programId } = payload
    email.value = await emailService.renderTemplate({
      id: draft.templateId,
      request: {
        dealId,
        customMessage: body.value,
        documentIds: draft.documentIds,
        marketId,
        programId,
        recipients: draft.primaryEmailAddresses
      }
    } as RenderTemplateType)
    if (!subject.value) {
      subject.value = email.value.subject as string
    }
  }

  return {
    body,
    debouncedBody,
    subject,
    dialog,
    loading,
    email,
    emailBody,
    debouncedEmailBody,
    template,
    wholeTemplate,
    confirmDialog,
    handleEditTemplate,
    getDocumentName,
    handleUpdatePreview,
    resetContent
  }
}
