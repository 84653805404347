import type { AMBestCompanyModel } from '@/capability/ambest/types'
import { apiConfiguration } from '@/capability/system/config'
import { AMBestApi } from 'typescript-core-api-client'

export interface AMBestService {
  getAll: (name: string | undefined) => Promise<AMBestCompanyModel[]>
}

export const amBestService = {
  getAll: async function (name: string | undefined): Promise<AMBestCompanyModel[]> {
    const config = await apiConfiguration()
    const ambestApi = new AMBestApi(config)
    const response = await ambestApi.getAll(name)
    return response.data.companies || []
  }
} satisfies AMBestService
