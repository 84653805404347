import { useAcl } from 'vue-simple-acl/src'
import type { AxiosRequestConfig } from 'axios'

import { useAuthStore } from '@/stores/auth'
import { useProgramStore } from '@/stores/program'

import {
  type AssignMarketIndicationModel,
  type IndicationLayerPairModel,
  type IndicationLineItemPairModel,
  type IndicationMarketAndIndicationPairsResponse,
  type IndicationMarketModel,
  type IndicationMarketPairsModel,
  type IndicationModel
} from '@/capability/indication/types'
import { apiConfiguration } from '@/capability/system/config'
import type { ConfirmBindOrderRequestBillingOriginationEnum, IndicationMarketDtoDealProgressEnum } from 'typescript-core-api-client'
import { IndicationApi, type UpdateIndicationMarketRequest } from 'typescript-core-api-client'
import type {
  CreateBindOrderRequestFilingResponsibilityEnum,
  CreateQuoteLetterRequestExportFormatEnum,
  IndicationMarketDtoStatusEnum,
  RejectRequestRejectedReasonTypeEnum
} from 'typescript-core-api-client/dist/api'
import { type IndicationMarketDto } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type CreateMarketsPayloadType = {
  addMarkets: AssignMarketIndicationModel[]
}

export type GetIndicationByProgramPayloadType = {
  programId: string
}

export type GetIndicationMarketByIdPayloadType = {
  indicationMarketId: string
}

export type GetIndicationMarketsPairsByProgramIdsPayloadType = {
  programIds: string[]
}
export type GetIndicationMarketsPairsPayloadType = {
  programId: string
}

export type GetLayerPairsPayloadType = {
  dealId: string
  marketId?: string
}

export type GetLineItemPairsPayloadType = {
  dealId: string
  programId?: string
  marketId?: string
}

export type SaveLayerPairsPayloadType = {
  layers: IndicationLayerPairModel[]
}

export type SaveLineItemPairsPayloadType = {
  lineItems: IndicationLineItemPairModel[]
}

export type UpdateIndicationMarketProgressPayloadType = {
  programId: string
  marketId: string
  dealProgress: IndicationMarketDtoDealProgressEnum
}

export type UpdateMarketToBlockedPayloadType = {
  dealId: string
  marketId: string
}

export type UpdateMarketToUnblockedPayloadType = {
  dealId: string
  marketId: string
}

export type UpdateMarketPayloadType = {
  market: IndicationMarketModel
}

export type CreateQuotePayloadType = {
  programId: string
  marketId: string
}

export type DeleteQuotePayloadType = {
  programId: string
  marketId: string
}

export type ConfirmQuotePayloadType = {
  programId: string
  marketId: string
}

export type TransitionPayloadType = {
  programId: string
  marketId: string
  status: string
}

export type CreateBindOrderPayloadType = {
  programId: string
  marketId: string
  request?: { filingResponsibility: CreateBindOrderRequestFilingResponsibilityEnum }
}

export type ConfirmBindOrderPayloadType = {
  programId: string
  marketId: string
  request?: { billingOrigination: ConfirmBindOrderRequestBillingOriginationEnum }
}

export type GetIndicationMarketsPayloadType = {
  programIds: string[]
}

export type GetCarrierIndicationMarketsByStatusesPayloadType = {
  programIds: string[]
  statuses?: IndicationMarketDtoStatusEnum[]
}

export type GetMarketsAndIndicationPairsPayloadType = {
  dealId: string
  programId: string
  statuses?: IndicationMarketDtoStatusEnum[]
}

export type RejectPayloadType = {
  programId: string
  marketId: string
  rejectedReasonType: RejectRequestRejectedReasonTypeEnum
  comment: string
  sovHeaderNameList: string[]
  internalComment: string
}

export type DeleteLineItemTargetPayloadType = {
  lineItemPairId: string
}

export type DeleteLineItemTargetsPayloadType = {
  lineItemPairIds: string[]
}

export type CreateLayerPairsPayloadType = {
  programId: string
}

export type ConvertToLeadTermsPayloadType = {
  programId: string
  marketId: string
}

export type GenerateQuoteLetterPayloadType = {
  dealId: string
  programId: string
  towerId: string
  marketIds: string[]
  exportFormat: CreateQuoteLetterRequestExportFormatEnum
}

export interface IndicationService {
  createMarkets: (payload: CreateMarketsPayloadType) => Promise<IndicationMarketDto[]>
  getIndicationByProgram: (payload: GetIndicationByProgramPayloadType) => Promise<IndicationModel[]>
  getIndicationMarketsPairsByProgramIds: (
    payload: GetIndicationMarketsPairsByProgramIdsPayloadType
  ) => Promise<IndicationMarketPairsModel | undefined>
  /**
   * IndicationMarketPairs represents the summary and the list of assigned carriers for this program
   */
  getIndicationMarketsPairs: (payload: GetIndicationMarketsPairsPayloadType) => Promise<IndicationMarketPairsModel>
  getLayerPairs: (payload: GetLayerPairsPayloadType) => Promise<IndicationLayerPairModel[]>
  getLineItemPairs: (payload: GetLineItemPairsPayloadType) => Promise<IndicationLineItemPairModel[]>
  saveLayerPairs: (payload: SaveLayerPairsPayloadType) => Promise<IndicationLayerPairModel[]>
  saveLineItemPairs: (payload: SaveLineItemPairsPayloadType) => Promise<IndicationLineItemPairModel[]>
  updateIndicationMarketProgress: (payload: UpdateIndicationMarketProgressPayloadType) => Promise<void>
  updateMarketToBlocked: (payload: UpdateMarketToBlockedPayloadType) => Promise<void>
  updateMarketToUnblocked: (payload: UpdateMarketToUnblockedPayloadType) => Promise<void>
  updateMarket: (payload: UpdateMarketPayloadType) => Promise<IndicationMarketModel>
  /**
   * Create a formal quote for the given program and market
   */
  createQuote: (payload: CreateQuotePayloadType) => Promise<void>
  deleteQuote: (payload: DeleteQuotePayloadType) => Promise<void>
  confirmQuote: (payload: ConfirmQuotePayloadType) => Promise<void>
  transition: (payload: TransitionPayloadType) => Promise<void>
  createBindOrder: (payload: CreateBindOrderPayloadType) => Promise<void>
  confirmBindOrder: (payload: ConfirmBindOrderPayloadType) => Promise<void>
  getIndicationMarkets: (payload: GetIndicationMarketsPayloadType) => Promise<IndicationMarketModel[]>
  getCarrierIndicationMarketsByStatuses: (payload: GetCarrierIndicationMarketsByStatusesPayloadType) => Promise<IndicationMarketModel[]>
  /**
   * Return all the market and indication pairs data filtered by the statuses for in a specific program
   */
  getMarketsAndIndicationPairs: (payload: GetMarketsAndIndicationPairsPayloadType) => Promise<IndicationMarketAndIndicationPairsResponse>
  /**
   * Reject the given market for a specific program
   */
  reject: (payload: RejectPayloadType) => Promise<void>
  deleteLineItemTarget: (payload: DeleteLineItemTargetPayloadType) => Promise<void>
  deleteLineItemTargets: (payload: DeleteLineItemTargetsPayloadType) => Promise<void>
  createLayerPairs: (payload: CreateLayerPairsPayloadType) => Promise<void>
  convertToLeadTerms: (payload: ConvertToLeadTermsPayloadType) => Promise<string>
  generateQuoteLetter: (payload: GenerateQuoteLetterPayloadType) => Promise<string>
}

export const indicationService = {
  createMarkets: async function ({ addMarkets }: CreateMarketsPayloadType): Promise<IndicationMarketDto[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.createIndicationMarket({ addMarkets })
    return response.data.newIndicationMarkets || []
  },
  getIndicationByProgram: async function ({ programId }: GetIndicationByProgramPayloadType): Promise<IndicationModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.getIndicationsByProgram(programId)
    return response.data.indications || []
  },
  getIndicationMarketsPairsByProgramIds: async function ({
    programIds
  }: GetIndicationMarketsPairsByProgramIdsPayloadType): Promise<IndicationMarketPairsModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.getIndicationMarketPairsByProgramIds(programIds, {
      headers: { 'Cache-Control': 'max-age=1' }
    } as AxiosRequestConfig)
    return response.data.marketSummaryPairs || ({} as IndicationMarketPairsModel)
  },
  getIndicationMarketsPairs: async function ({
    programId
  }: GetIndicationMarketsPairsPayloadType): Promise<IndicationMarketPairsModel | undefined> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.getIndicationMarketPairsById(programId, {
      headers: { 'Cache-Control': 'max-age=1' }
    } as AxiosRequestConfig)
    return response.data.marketSummaryPairs
  },
  getLayerPairs: async function (payload: GetLayerPairsPayloadType): Promise<IndicationLayerPairModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { dealId, marketId } = payload
    const response = await indicationApi.getIndicationLayerPairs(dealId, marketId, {
      headers: { 'Cache-Control': 'max-age=1' }
    } as AxiosRequestConfig)
    return response.data.layers ?? []
  },
  getLineItemPairs: async function (payload: GetLineItemPairsPayloadType): Promise<IndicationLineItemPairModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { dealId, marketId, programId } = payload
    const response = await indicationApi.getIndicationLineItemPairs(dealId, marketId, programId, {
      headers: { 'Cache-Control': 'max-age=1' }
    } as AxiosRequestConfig)
    return response.data.lineItems ?? []
  },
  saveLayerPairs: async function ({ layers }: SaveLayerPairsPayloadType): Promise<IndicationLayerPairModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.createOrUpdateIndicationLayerPairs({ layers })
    return response.data.layers ?? []
  },
  saveLineItemPairs: async function ({ lineItems }: SaveLineItemPairsPayloadType): Promise<IndicationLineItemPairModel[]> {
    if (lineItems.length === 0) {
      return []
    }
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.createOrUpdateIndicationLineItemPairs({ lineItems })
    return response.data.lineItems ?? []
  },
  updateIndicationMarketProgress: async function (payload: UpdateIndicationMarketProgressPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { marketId, dealProgress, programId } = payload
    await indicationApi.updateIndicationMarketProgress(marketId, { marketId, dealProgress, programId })
  },
  updateMarketToBlocked: async function (payload: UpdateMarketToBlockedPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { dealId, marketId } = payload
    await indicationApi.block({ dealId, marketId })
  },
  updateMarketToUnblocked: async function (payload: UpdateMarketToUnblockedPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { dealId, marketId } = payload
    await indicationApi.unblock({ dealId, marketId })
  },
  /**
   * There are 4 different update methods due to alias listed in this endpoint. ("publish/markets", "publish/brokers", "drafts", "aligned")
   * But the order is reversed so "publish/markets" is the last one in the list which is `update_5`.
   */
  updateMarket: async function ({ market }: UpdateMarketPayloadType): Promise<IndicationMarketModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.update_5({ indicationMarkets: [market] } as UpdateIndicationMarketRequest)
    return response.data.indicationMarkets?.[0] as IndicationMarketModel
  },
  createQuote: async function (payload: CreateQuotePayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId } = payload
    const marketPairs = await this.getIndicationMarketsPairs({ programId })
    const indicationMarket = marketPairs?.indicationMarkets?.find(
      (market) => market.marketId === marketId && market.createdByUserType === 'Carrier'
    )
    if (!indicationMarket) {
      throw new Error('indication market cannot be null')
    }
    await indicationApi.createFormalQuote(indicationMarket.indicationMarketId as string, {
      indicationMarketId: indicationMarket.indicationMarketId
    })
  },
  deleteQuote: async function (payload: DeleteQuotePayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId } = payload
    await indicationApi.removeIndicationMarketByProgram({ programId, marketId })
  },
  confirmQuote: async function (payload: ConfirmQuotePayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId } = payload
    const marketPairs = await this.getIndicationMarketsPairs({ programId })
    const indicationMarket = marketPairs?.indicationMarkets?.find(
      (market) => market.marketId === marketId && market.createdByUserType === 'Carrier'
    )
    if (!indicationMarket) {
      throw new Error('indication market cannot be null')
    }

    await indicationApi.confirmFormalQuote(indicationMarket.indicationMarketId as string, {
      indicationMarketId: indicationMarket.indicationMarketId
    })
  },
  transition: async function (payload: TransitionPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId, status } = payload
    const marketPairs = await this.getIndicationMarketsPairs({ programId })
    const indicationMarket = marketPairs?.indicationMarkets?.find(
      (market) => market.marketId === marketId && market.createdByUserType === 'Carrier'
    )
    if (!indicationMarket) {
      throw new Error('indication market cannot be null')
    }

    await indicationApi.transitionIndicationMarketStatus({
      indicationMarketId: indicationMarket.indicationMarketId,
      transitionToStatus: status
    })
  },
  createBindOrder: async function (payload: CreateBindOrderPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId, request } = payload
    const marketPairs = await this.getIndicationMarketsPairs({ programId })
    const indicationMarket = marketPairs?.indicationMarkets?.find(
      (market) => market.marketId === marketId && market.createdByUserType === 'Carrier'
    )
    if (!indicationMarket) {
      throw new Error('indication market cannot be null')
    }

    await indicationApi.createBindOrder(indicationMarket.indicationMarketId!, request)
  },
  confirmBindOrder: async function (payload: ConfirmBindOrderPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId, request } = payload
    const marketPairs = await this.getIndicationMarketsPairs({ programId })
    const indicationMarket = marketPairs?.indicationMarkets?.find(
      (market) => market.marketId === marketId && market.createdByUserType === 'Carrier'
    )
    if (!indicationMarket) {
      throw new Error('indication market cannot be null')
    }

    await indicationApi.confirmBindOrder(indicationMarket.indicationMarketId!, request)
  },
  getIndicationMarkets: async function ({ programIds }: GetIndicationMarketsPayloadType): Promise<IndicationMarketModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const response = await indicationApi.getIndicationMarketsByProgramIds(programIds)
    return response.data.indicationMarkets || []
  },
  getCarrierIndicationMarketsByStatuses: async function (
    payload: GetCarrierIndicationMarketsByStatusesPayloadType
  ): Promise<IndicationMarketModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programIds, statuses } = payload
    const allIndicationMarkets = await this.getIndicationMarkets({ programIds })
    return (
      allIndicationMarkets?.filter(
        (indicationMarket) =>
          indicationMarket.createdByUserType == 'Carrier' &&
          indicationMarket.status &&
          (!statuses || statuses.includes(indicationMarket.status))
      ) || []
    )
  },
  getMarketsAndIndicationPairs: async function (
    payload: GetMarketsAndIndicationPairsPayloadType
  ): Promise<IndicationMarketAndIndicationPairsResponse> {
    const { dealId, programId, statuses } = payload
    const allIndicationMarkets = await this.getIndicationMarketsPairs({ programId })
    const authStore = useAuthStore()
    const acl = useAcl()

    const filteredMarkets =
      allIndicationMarkets?.indicationMarkets?.filter(
        (indicationMarket) =>
          indicationMarket.createdByUserType == 'Carrier' &&
          indicationMarket.status &&
          (!statuses || statuses.includes(indicationMarket.status)) &&
          (acl.anyRole(['admin', 'rs', 'retailer']) || indicationMarket.marketId == authStore.userOrganizationId)
      ) || []

    const allLayerPairs = await this.getLayerPairs({ dealId })
    const marketIds = filteredMarkets?.map((pair) => pair.programId == programId && pair.marketId) || []
    const filteredLayerPairs = allLayerPairs.filter(
      (layerPair) => marketIds.includes(layerPair.marketId) && layerPair.programId === programId
    )

    const lineItemPairsModel = await this.getLineItemPairs({ dealId, programId })

    // set editToggle visibility to false if the current user is a carrier and its market has been moved to bind authorized or bind ordered
    if (acl.anyRole(['carrier'])) {
      const programStore = useProgramStore()
      programStore.isEditToggleVisible =
        filteredMarkets.filter((market) => market.dealProgress == 'BindAuthorized' || market.dealProgress == 'BindOrdered').length == 0
    }
    return { markets: filteredMarkets, pairs: filteredLayerPairs, lineItemPairs: lineItemPairsModel }
  },
  reject: async function (payload: RejectPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId, comment, rejectedReasonType, sovHeaderNameList, internalComment } = payload
    const imPairs = await this.getIndicationMarketsPairs({ programId })
    const im = imPairs?.indicationMarkets?.filter((market) => market.marketId == marketId && market.createdByUserType == 'Carrier')
    if (!im) {
      throw new Error('carrier indication market cannot be null')
    }

    await indicationApi.rejectAll({
      comment,
      indicationMarketId: im[0].indicationMarketId,
      rejectedReasonType,
      sovHeaderNameList,
      internalComment
    })
  },
  deleteLineItemTarget: async function ({ lineItemPairId }: DeleteLineItemTargetPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    await indicationApi.deleteIndicationLineItemPairs({ lineItemPairIds: [lineItemPairId] })
  },
  deleteLineItemTargets: async function ({ lineItemPairIds }: DeleteLineItemTargetsPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    await indicationApi.deleteIndicationLineItemPairs({ lineItemPairIds })
  },
  createLayerPairs: async function ({ programId }: CreateLayerPairsPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    await indicationApi.createLayerPairs(programId)
  },
  convertToLeadTerms: async function (payload: ConvertToLeadTermsPayloadType): Promise<string> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { programId, marketId } = payload
    const response = await indicationApi.convertToLeadTerms(programId, { programId, marketId })
    return response.data.programId ?? ''
  },
  generateQuoteLetter: async function (payload: GenerateQuoteLetterPayloadType): Promise<string> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const indicationApi = new IndicationApi(config)

    const { dealId, programId, towerId, marketIds, exportFormat } = payload
    const response = await indicationApi.createQuoteLetter({ dealId, programId, towerId, marketIds, exportFormat })
    return response.data.content ?? ''
  }
} satisfies IndicationService
