<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useAcl } from 'vue-simple-acl/src'

import type { DataTableHeader } from '@/lib/types/data-table'
import { truncateText } from '@/lib/utils/formatting'
import { useLineItemsStore } from '@/stores/line-item'

import type { LineItemModel } from '@/capability/line-item/types'
import { userService } from '@/capability/user/service'
import type { UserModel } from '@/capability/user/types'
import type { LineItemDtoTypeEnum } from 'typescript-core-api-client/dist/api'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import ProgramLineItemDelete from '@/component/program/program-line-items/ProgramLineItemDelete.vue'
import ProgramLineItemEdit from '@/component/program/program-line-items/ProgramLineItemEdit.vue'
import ProgramLineItemEnable from '@/component/program/program-line-items/ProgramLineItemEnable.vue'
import ProgramLineItemsManagementTypeSelector from '@/component/program/program-line-items/ProgramLineItemsManagementTypeSelector.vue'

const COLUMNS: DataTableHeader[] = [
  {
    text: 'Name',
    value: 'name',
    align: 'start',
    sortable: true
  },
  {
    text: 'Status',
    value: 'status',
    sortable: true
  },
  {
    text: 'Created By',
    value: 'userId',
    sortable: true
  },
  {
    text: 'Type',
    value: 'type',
    sortable: true
  },
  {
    text: '',
    value: 'actions'
  }
]

const ITEM_CLASSES = {
  name: 'text-start'
  // type: 'text-end'
}

const TRUNCATE_LENGTH = 64

const acl = useAcl()
const lineItemsStore = useLineItemsStore()

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)
const search = ref<string>('')
const users = ref<UserModel[]>([])
const hideDisabled = ref<boolean>(true)

const lineItemTypes = computed(
  (): LineItemDtoTypeEnum[] => [...new Set(lineItemsStore.lineItems!.map((l: LineItemModel) => l.type))] as LineItemDtoTypeEnum[]
)
const lineItemType = ref<LineItemDtoTypeEnum[]>([])

function getUser(item: LineItemModel): string {
  if (item.accountId === 'GLOBAL') return 'Global'
  return users.value.find((u) => u.id === item.userId)?.email ?? ''
}
const actionsDisabled = (item: LineItemModel): boolean => item.accountId === 'GLOBAL' || !acl.anyRole(['rs', 'admin'])
const filteredItems = computed(() => {
  const _lineItems = lineItemsStore
    .lineItems!.filter(({ type }) => lineItemType.value.includes(type))
    .filter(({ status }) => {
      if (hideDisabled.value) {
        return status === 'Active'
      }
      return true
    })
  if (!search.value) {
    return _lineItems
  }
  return _lineItems.filter(
    (item) =>
      item.name?.toLowerCase().includes(search.value.toLowerCase()) ||
      getUser(item).toLowerCase().includes(search.value.toLowerCase()) ||
      item.description?.toLowerCase().includes(search.value.toLowerCase())
  )
})

function resetState() {
  search.value = ''
  lineItemType.value = lineItemTypes.value
}

async function fetchUsers() {
  // Create array of promises for each user fetch
  const userPromises = lineItemsStore
    .lineItems!.filter((l) => !!l.userId)
    .map(({ userId }) => userService.getById({ userId: userId as string }))

  // Return the promise chain instead of using async/await
  return Promise.allSettled(userPromises).then((results) => {
    // Filter out rejected promises and extract values from fulfilled ones
    const fulfilledUsers = results.filter((result) => result.status === 'fulfilled').map((result) => result.value)

    users.value = fulfilledUsers
    return fulfilledUsers
  })
}

watch(dialog, async (value) => {
  if (!value) {
    resetState()
  } else {
    lineItemType.value = lineItemTypes.value
    loading.value = true
    await fetchUsers()
    loading.value = false
  }
})

function handleAddNewLineItem() {
  lineItemsStore.$patch({
    lineItemName: search.value,
    lineItemManagementDialog: true,
    lineItemInputMode: 'add'
  })
}
</script>

<template>
  <TooltipProvider :delay-duration="100">
    <Tooltip>
      <TooltipTrigger>
        <Dialog v-model:open="dialog">
          <DialogTrigger as-child>
            <rq-btn id="programs-navbar-line-items-management-button" icon="square" size="lg" variant="ghost-primary">
              <rq-icon icon="lucide:clipboard-pen-line" />
            </rq-btn>
          </DialogTrigger>
          <DialogContent class="w-[95vw] max-w-[100vw] sm:ml-16 sm:w-[85vw] lg:ml-0 lg:w-[75vw] xl:w-[65vw]">
            <DialogHeader>
              <DialogTitle>Line Items Management</DialogTitle>
              <DialogDescription>Add, remove, or edit line items to be used platform-wide.</DialogDescription>
            </DialogHeader>
            <div class="grid grid-cols-1 items-end gap-4 pb-4 md:grid-cols-2 lg:grid-cols-6">
              <rq-text-field
                v-model="search"
                id="search"
                class="col-span-1 lg:col-span-2"
                clearable
                label="Filter"
                prepend-icon="mdi:magnify"
              />
              <ProgramLineItemsManagementTypeSelector v-model="lineItemType" class="col-span-1 lg:col-span-2" :items="lineItemTypes" />
              <rq-switch v-model="hideDisabled" class="mx-auto" label="Disabled Visibility">
                <template #action-text>
                  <small>
                    {{ hideDisabled ? 'Hidden' : 'Visible' }}
                  </small>
                </template>
              </rq-switch>
              <rq-btn class="col-span-1" variant="primary" @click="handleAddNewLineItem">Add Line Item</rq-btn>
            </div>
            <rq-data-table
              class="max-h-[45vh]"
              :headers="COLUMNS"
              :item-classes="ITEM_CLASSES"
              :items="filteredItems"
              :loading="loading"
              :sticky-header="true"
            >
              <template #name="{ item }">
                <span>
                  {{ truncateText(item.name, TRUNCATE_LENGTH) }}
                  <rq-tooltip v-if="item.name?.length > TRUNCATE_LENGTH" :text="item.name" />
                </span>
              </template>
              <template #userId="{ item }">
                <span>{{ getUser(item) }}</span>
              </template>
              <template #actions="{ item }">
                <DropdownMenu>
                  <DropdownMenuTrigger as-child>
                    <rq-btn :disabled="actionsDisabled(item)" variant="primary">Actions</rq-btn>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <ProgramLineItemEdit :disabled="item.status === 'Disabled'" :line-item="item" />
                    <ProgramLineItemEnable :disabled="item.status === 'Active'" :line-item="item" />
                    <ProgramLineItemDelete :disabled="item.status === 'Disabled'" :line-item="item" />
                  </DropdownMenuContent>
                </DropdownMenu>
              </template>
              <template #no-data>
                <div v-if="search">
                  No line items were found using the search term "{{ search }}".
                  <rq-btn class="mx-auto mt-3 block italic" variant="primary-outline" @click="handleAddNewLineItem">
                    Click here to add a new one.
                  </rq-btn>
                </div>
              </template>
            </rq-data-table>
          </DialogContent>
        </Dialog>
      </TooltipTrigger>
      <TooltipContent>Line Items Management</TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>

<style scoped lang="scss"></style>
