import type { RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import type { Ability } from 'vue-simple-acl'
import { useAcl } from 'vue-simple-acl/src'

import { getLogger } from '@/composables/util/log/logger'
import type { PageMetaType, PageViewType } from '@/lib/types/router'
import { rqDatadog } from '@/lib/utils/datadog'
import { setDatadogRumUser } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'
import { useDealKickoffStore } from '@/stores/deal/kickoff'
import { useNotificationStore } from '@/stores/notification'

import { signinService } from '@/capability/authentication/service'
import { deleteAccessTokenCookie, getAccessTokenCookieValue } from '@/capability/authentication/utils'
import { systemEventCaptureService } from '@/capability/event/service'
import type { SystemEvent } from '@/capability/event/types'
import {
  buildLoginPageViewTemplate,
  createSystemEvent,
  CUSTOM_CRUD_DOES_ALREADY_EXIST,
  USER_SESSION_RESOURCE_TYPE
} from '@/capability/event/utils'
import { runtimeConfigService } from '@/capability/runtime-config/service'
import type { UserModel } from '@/capability/user/types'
import type { FeatureDto, WhoAmIResponse } from 'typescript-core-api-client/dist/api'
import { DealConfigDtoAssetProfileEnum } from 'typescript-core-api-client/dist/api'

import AdminRoutes from './admin'
import AuthRoutes from './auth'
import BillingRoutes from './billing'
import BusinessIntelligenceRoutes from './business-intelligence'
import CarrierRoutes from './carrier'
import DocumentRoutes from './documents'
import OpenRoutes from './open'
import ProgramRoutes from './program'
import RetailerRoutes from './retailer'
import { getRiskAttributesRoutes } from './risk-attributes'
import UserRoutes from './user'

const logger = getLogger('router')

const title = 'arqu app'

export const routes: RouteRecordRaw[] = [
  ...AuthRoutes,
  {
    path: '/just-the-towers',
    component: () => import('@/component/tower/TowerSchematicComponent.vue')
  },
  {
    path: '/a2z',
    component: () => import('@/views/admin/AdminPageComponent.vue'),
    children: AdminRoutes,
    meta: {
      requiresAuth: true,
      access: {
        roles: ['rs', 'admin']
      }
    }
  },
  {
    path: '/',
    component: () => import('@/component/root/RootComponent.vue'),
    children: [
      BusinessIntelligenceRoutes,
      {
        path: '',
        component: () => import('@/views/redirect.vue')
      },
      {
        path: '/deal-queue',
        name: 'DealQueueList',
        component: () => import('@/views/deal/deal-queue/DealQueueList.vue'),
        meta: {
          pageViewDetails: {
            pageId: 'deal-queue',
            title: 'Deal Queue'
          },
          requiresAuth: true,
          title: 'Deal Queue',
          access: {
            roles: ['rs', 'admin']
          }
        }
      },
      {
        path: '/deal-queue/:dealId',
        redirect: function (to) {
          return { name: 'DealCreateNewInfoStep', query: { dealId: to.params.dealId } }
        }
      },
      UserRoutes,
      RetailerRoutes,
      {
        path: 'conversion',
        component: () => import('@/views/conversion/DealConversionDashboard.vue'),
        name: 'DealConversionDashboard',
        meta: {
          pageViewDetails: {
            pageId: 'deal-retailer-list-lego',
            resourceType: 'deal',
            resourceCrudl: 'list',
            title: 'Deal Hub Lego'
          },
          requiresAuth: true,
          roles: ['rs', 'admin']
        }
      },
      {
        path: '/deals',
        children: [
          {
            path: '',
            component: () => import('@/views/deal/list/DealList.vue'),
            name: 'DealListView',
            meta: {
              pageViewDetails: {
                pageId: 'deal-list',
                resourceType: 'deal',
                resourceCrudl: 'list',
                title: 'Deal Hub'
              },
              requiresAuth: true,
              title: 'Deal Hub',
              access: {
                roles: ['admin', 'rs']
              }
            }
          },
          {
            path: 'new',
            component: () => import('@/views/deal/new/DealKickoffLayout.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/deal/new/info-step/CreateDealInfoStep.vue'),
                name: 'DealCreateNewInfoStep',
                meta: {
                  pageViewDetails: {
                    pageId: 'deal-create-new-info-step',
                    resourceType: 'deal',
                    resourceCrudl: 'new',
                    title: 'Create New Deal'
                  },
                  requiresAuth: true,
                  title: 'Create New Deal',
                  access: {
                    roles: ['rs', 'admin']
                  }
                }
              },
              {
                path: 'location',
                component: () => import('@/views/deal/new/location-step/CreateDealLocationStep.vue'),
                name: 'DealCreateNewLocationStep',
                meta: {
                  pageViewDetails: {
                    pageId: 'deal-create-new-location-step',
                    resourceType: 'deal',
                    resourceCrudl: 'new',
                    title: 'Deal Location'
                  },
                  requiresAuth: true,
                  title: 'Deal Location',
                  access: {
                    roles: ['rs', 'admin']
                  }
                }
              },
              {
                path: 'retailer',
                component: () => import('@/views/deal/new/retailer-step/CreateDealRetailerStep.vue'),
                name: 'DealCreateNewRetailerStep',
                meta: {
                  pageViewDetails: {
                    pageId: 'deal-create-new-retailer-step',
                    resourceType: 'deal',
                    resourceCrudl: 'new',
                    title: 'Deal Retailer'
                  },
                  requiresAuth: true,
                  title: 'Deal Retailer',
                  access: {
                    roles: ['rs', 'admin']
                  }
                }
              },
              {
                path: 'program',
                component: () => import('@/views/deal/new/program-step/CreateDealProgramStep.vue'),
                name: 'DealCreateNewProgramStep',
                meta: {
                  pageViewDetails: {
                    pageId: 'deal-create-new-program-step',
                    resourceType: 'deal',
                    resourceCrudl: 'new',
                    title: 'Deal Program'
                  },
                  requiresAuth: true,
                  title: 'Deal Program',
                  access: {
                    roles: ['rs', 'admin']
                  }
                }
              },
              {
                path: 'review',
                component: () => import('@/views/deal/new/review-step/CreateDealReviewStep.vue'),
                name: 'DealCreateNewReviewStep',
                meta: {
                  pageViewDetails: {
                    pageId: 'deal-create-new-review-step',
                    resourceType: 'deal',
                    resourceCrudl: 'new',
                    title: 'Deal Review'
                  },
                  requiresAuth: true,
                  title: 'Deal Review',
                  access: {
                    roles: ['rs', 'admin']
                  }
                }
              }
            ]
          },
          {
            path: 'retailer',
            redirect: { name: 'DealListView' }
          },
          {
            path: ':dealId',
            component: () => import('@/component/deal/DealIndividualSandboxComponent.vue'),
            children: [
              {
                path: '',
                alias: 'manager',
                component: () => import('@/views/deal/detail/DealDetailView/DealDetailView.vue'),
                name: 'DealDetailView',
                meta: {
                  ruleName: 'deal-read-info',
                  pageViewDetails: {
                    pageId: 'deal-read',
                    resourceType: 'deal',
                    resourceCrudl: 'read',
                    title: 'Deal Manager'
                  },
                  title: 'Deal Manager',
                  requiresAuth: true,
                  access: {
                    roles: ['admin', 'rs']
                  }
                }
              },
              {
                path: 'legacy',
                redirect: (to) => {
                  return { name: 'DealDetailView', params: { ...to.params }, query: { ...to.query } }
                }
              },
              ProgramRoutes,
              getRiskAttributesRoutes(),
              ...DocumentRoutes,
              {
                path: 'organization-fetch-links',
                component: () => import('@/component/organization/OrganizationFetchLinksComponent.vue'),
                name: 'OrganizationFetchLinks',
                meta: {
                  pageViewDetails: {
                    pageId: 'organization-fetch-links',
                    resourceType: 'organizationFetchLinks',
                    resourceCrudl: 'list'
                  },
                  requiresAuth: true,
                  title: 'Fetch Links'
                }
              },
              {
                path: 'messages',
                name: 'DealMessages',
                component: () => import('@/views/messages/DealMessages.vue'),
                meta: {
                  ruleName: 'messages-read',
                  pageViewDetails: {
                    pageId: 'deal-messages-list',
                    resourceType: 'deal-message',
                    resourceCrudl: 'list'
                  },
                  requiresAuth: true,
                  title: 'Deal Messages'
                }
              },
              {
                path: 'clearing-submission',
                name: 'ClearingAndSubmission',
                component: () => import('@/views/clearing-submission/ClearingAndSubmissionBase.vue'),
                children: [
                  {
                    path: 'draft',
                    name: 'ClearingAndSubmissionDraft',
                    component: () => import('@/views/clearing-submission/ClearingSubmissionDraft.vue'),
                    meta: {
                      requiresAuth: true,
                      title: 'Clearing and Submissions Draft'
                    }
                  },
                  {
                    path: '',
                    name: 'ClearingAndSubmissionListAndDetail',
                    component: () => import('@/views/clearing-submission/ClearingSubmissionListAndDetail.vue'),
                    meta: {
                      requiresAuth: true,
                      title: 'Clearing and Submissions'
                    }
                  }
                ],
                meta: {
                  requiresAuth: true
                }
              },
              BillingRoutes
            ]
          },
          {
            path: ':dealId/reviewSOV/:documentId',
            component: () => import('@/views/deal/StatementOfValuesReview.vue'),
            name: 'StatementOfValuesReview',
            meta: {
              pageViewDetails: {
                pageId: 'deal-sov-read',
                resourceType: 'deal-sov',
                resourceCrudl: 'read'
              },
              requiresAuth: true,
              title: 'Statement of Values'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/risk-narrative',
    redirect: { name: 'RiskNarrative-Open' },
    name: 'RiskNarrativeOpenView'
  },
  {
    path: '/carriers',
    redirect: { name: 'Documents-Open' },
    name: 'CarrierView'
  },
  {
    path: '/documents/preview/:docId',
    redirect: { name: 'DocumentPreview-Open' },
    name: 'CarrierDocumentPreview'
  },
  OpenRoutes,
  ...CarrierRoutes,
  {
    path: '/deals/:dealId/documents/preview/:docId',
    component: () => import('@/component/deal/DealDataWrapper.vue'),
    children: [
      {
        path: '',
        name: 'DocumentPreview',
        component: () => import('@/views/documents/DocumentPreview.vue'),
        meta: {
          ruleName: 'document-read',
          pageViewDetails: {
            pageId: 'deal-document-preview',
            resourceType: 'deal-document',
            resourceCrudl: 'preview'
          },
          title: 'Document Preview'
        }
      }
    ]
  },
  {
    path: '/retailer/deals/:dealId/documents/preview/:docId',
    component: () => import('@/component/deal/DealDataWrapper.vue'),
    children: [
      {
        path: '',
        name: 'DocumentPreview-Retailer',
        component: () => import('@/views/documents/DocumentPreview.vue'),
        meta: {
          ruleName: 'document-read',
          pageViewDetails: {
            pageId: 'deal-document-preview',
            resourceType: 'deal-document',
            resourceCrudl: 'preview'
          },
          title: 'Document Preview'
        }
      }
    ]
  },
  {
    path: '/deals/:dealId/documents/spreadsheet-viewer/:docId',
    component: () => import('@/component/deal/DealDataWrapper.vue'),
    children: [
      {
        path: '',
        name: 'DocumentSpreadsheetViewer',
        component: () => import('@/views/documents/DocumentSpreadsheetViewer/DocumentSpreadsheetViewer.vue'),
        meta: {
          ruleName: 'document-read',
          pageViewDetails: {
            pageId: 'deal-document-spreadsheet-viewer',
            resourceType: 'deal-document',
            resourceCrudl: 'spreadsheet-viewer'
          },
          title: 'Document Spreadsheet Viewer'
        }
      }
    ]
  },
  {
    path: '/deals/:dealId/messages/preview/:messageId',
    component: () => import('@/component/deal/DealDataWrapper.vue'),
    children: [
      {
        path: '',
        name: 'MessagePreview',
        component: () => import('@/views/messages/DealMessagesPreview.vue'),
        meta: {
          ruleName: 'message-read',
          pageViewDetails: {
            pageId: 'deal-message-preview',
            resourceType: 'deal-message',
            resourceCrudl: 'preview'
          },
          title: 'Message Preview'
        }
      }
    ]
  },
  {
    path: '/retailer/deals/:dealId/messages/preview/:messageId',
    component: () => import('@/component/deal/DealDataWrapper.vue'),
    children: [
      {
        path: '',
        name: 'MessagePreview-Retailer',
        component: () => import('@/views/messages/DealMessagePreviewContent.vue'),
        meta: {
          ruleName: 'message-read',
          pageViewDetails: {
            pageId: 'deal-message-preview',
            resourceType: 'deal-message',
            resourceCrudl: 'preview'
          },
          title: 'Message Preview'
        }
      }
    ]
  },
  {
    path: '/deals/:dealId/map',
    component: () => import('@/views/deal/DealGoogleMapStandaloneComponent.vue'),
    name: 'DealGoogleMapStandaloneView',
    meta: {
      pageViewDetails: {
        pageId: 'deal-map',
        resourceType: 'deal-map',
        resourceCrudl: 'read'
      },
      requiresAuth: false,
      title: 'Deal Map'
    }
  },
  {
    path: '/dynamic-dashboard',
    component: () => import('@/views/dynamic-dashboard/DynamicDashboardBase.vue'),
    name: 'DynamicDashboardBase',
    meta: {
      requiresAuth: true,
      access: {
        roles: ['rs', 'admin']
      },
      title: 'Dynamic Dashboard'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/four-oh-four.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const routeExists = (name: string): boolean => router.hasRoute(name)

router.afterEach(async (to) => {
  const meta: PageMetaType = (to.meta ?? {}) as PageMetaType
  if (meta.pageViewDetails != null) {
    const details = meta.pageViewDetails as PageViewType
    const event: SystemEvent = createSystemEvent()
    systemEventCaptureService.fireAndForgetPageView({ ...event, ...details })
  }

  if (meta?.title) {
    document.title = `${meta.title} | ${title}`
  } else {
    document.title = title
  }
})

async function routerBeforeEach(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  const notificationStore = useNotificationStore()
  const dealKickoffStore = useDealKickoffStore()
  const acl = useAcl()

  /**********************************************************/

  const meta: PageMetaType = (to.meta ?? {}) as PageMetaType
  const pageId = meta.pageViewDetails?.pageId
  if (pageId) {
    rqDatadog.configure({
      payload: {
        context: pageId
      }
    })
  }

  // // If the retailer experience toggle is set to true then clicking "Leave Retailer Experience" won't actually do
  // // anything so we need to add a query parameter to tell the store to disable the retailer experience and then after
  // // navigating there remove the query parameter
  // if (to.query.disableRetailerExperience) {
  //   authStore._retailerView = 'false'
  //   delete to.query.disableRetailerExperience
  //   return to
  // }
  if (to.fullPath.includes('/deal/')) {
    return `/deals${to.fullPath.substring(5)}`
  }

  // fix legacy path from email
  if (to.fullPath.includes('/riskAttributes')) {
    return to.fullPath
      .replace('/riskAttributes', '/risk-attributes')
      .replace('Risk%20Snapshot', 'risk-snapshot')
      .replace('Risk+Snapshot', 'risk-snapshot')
      .replace('Risk Snapshot', 'risk-snapshot')
  }

  // New program UX handling
  // To be removed: https://arqu-co.atlassian.net/browse/RQ-5461
  if (to.meta?.isNewProgramRoute) {
    if (!authStore.newProgramsUxEnabled) {
      notificationStore.publishErrorMessage('Those pages are not available yet!')
      const programId = to.params?.programId
      if (programId) {
        return { name: 'ProgramLandingComponent', params: { dealId: to.params.dealId, programId } }
      } else {
        return { name: 'DealDetailView', params: { dealId: to.params.dealId } }
      }
    }
  }

  const cookieAccessToken = getAccessTokenCookieValue()

  if (!authStore.user && !cookieAccessToken && meta.requiresAuth !== false) {
    const query = { ...to.query, ...{ redirectUrl: authStore.getRedirectUrl(to) } }
    if (to.query.token) {
      return { name: 'ResetPassword', query: query }
    } else if (to.query.recipientOrgId || to.query.message) {
      const passwordlessAuthFeature = await runtimeConfigService.getFeatureFlag({ flag: 'passwordless-auth' })
      if (passwordlessAuthFeature) {
        return { name: 'Login', query: query }
      } else {
        return { name: 'Onboarding', query: query }
      }
    } else {
      return { name: 'Login', query: { redirectUrl: authStore.getRedirectUrl(to) } }
    }
  }

  if (
    to.name &&
    typeof to.name === 'string' &&
    [
      'Login',
      'LoginLanding',
      'VerifyLogin',
      'VerifyCode',
      'VerifyEmail',
      'Onboarding',
      'Register',
      'ForgotPassword',
      'ResetPassword'
    ].includes(to.name) &&
    authStore.user?.id
  ) {
    return '/'
  }

  // initialize the user access token and object
  if (cookieAccessToken && authStore.accessToken !== cookieAccessToken) {
    authStore.accessToken = cookieAccessToken
    try {
      const response: WhoAmIResponse = await signinService.whoami()
      const { user, features, refreshToken } = extractUserInfo(response)
      updateStore(user, features, refreshToken)
      rqDatadog.updateUser(user, features)
      setDatadogRumUser(user)

      // poll for the latest user object every 15 minutes
      setInterval(
        () => {
          signinService
            .whoami()
            .then(function (response: WhoAmIResponse) {
              const { user, features, refreshToken } = extractUserInfo(response)
              updateStore(user, features, refreshToken)
            })
            .catch(function (err) {
              logger.error(err as unknown as Error, { context: { method: 'polling for user every 15 minutes' } })
            })
        },
        1000 * 60 * 15
      )
    } catch (err) {
      if (to.name === 'VerifyLogin') {
        // no-ops since we want to stay in this view
      } else {
        logger.error(err as unknown as Error, { context: { method: 'trying to initialize user' } })
        if (err.response?.status >= 400 && err.response?.status <= 499) {
          const authStore = useAuthStore()
          authStore.$patch({
            accessToken: '',
            refreshToken: '',
            user: undefined
          })
          deleteAccessTokenCookie()
          systemEventCaptureService.fireAndForgetProgrammaticEvent({
            pageId: 'router-index',
            code: 'access-token-invalid',
            resourceType: 'user-session',
            resourceCrudl: 'delete'
          })
        }

        return authStore.getRedirectUrl(to)
      }
    }

    // subscribe to access token change
    authStore.$subscribe((mutation) => {
      if (mutation.type === 'direct' && mutation.storeId === 'auth' && mutation.events?.key === 'accessToken') {
        signinService.whoami().then((response: WhoAmIResponse) => {
          const { user, features, refreshToken } = extractUserInfo(response)
          updateStore(user, features, refreshToken)
          rqDatadog.updateUser(user, features)
        })
      }
    })
  }

  if (
    !!to.name &&
    ['RiskNarrative-Open', 'Documents-Open', 'DocumentPreview-Open', 'RiskStatementOfValues-Open'].includes(to.name as string)
  ) {
    return true
  }
  if (!!to.name && to.name === 'RiskStatementOfValues-Carrier' && !!authStore.user?.id && !acl.anyRole(['carrier'])) {
    return { name: 'RiskStatementOfValues', params: to.params }
  }
  /****************** LOCK DOWN CARRIER XP ******************/
  if (acl.anyRole(['carrier'])) {
    if (
      !!to.name &&
      [
        'CarrierDocumentPreview',
        'DocumentPreview-Carrier',
        'CarrierView',
        'Documents-Carrier',
        'RiskNarrative-Carrier',
        'RiskStatementOfValues',
        'RiskStatementOfValues-Carrier',
        'LoginLanding',
        'VerifyLogin',
        'DealListView-Carrier'
      ].includes(to.name as string)
    ) {
      return true
    }
    try {
      if (!to.name) {
        throw new Error(`No route name found. Route location is ${to.fullPath}`)
      }
      const baseName = to.name.split('-')[0]
      if (baseName === 'NotFound') return true

      const carrierName = `${baseName}-Carrier`

      if (!routeExists(carrierName)) {
        throw new Error(`That location was not found`)
      }

      return { ...to, name: carrierName }
    } catch (err) {
      return { name: 'DealListView-Carrier' }
    }
  }

  if (acl.anyRole(['retailer'])) {
    if (!!to.name && to.name.includes('-Retailer')) {
      return true
    }
    try {
      if (!to.name) {
        throw new Error(`No route name found. Route location is ${to.fullPath}`)
      }
      const baseName = to.name.split('-')[0]
      if (baseName === 'NotFound') return true

      const retailerName = `${baseName}-Retailer`

      if (!routeExists(retailerName)) {
        throw new Error('That location was not found')
      }

      return { ...to, name: retailerName }
    } catch (err) {
      return { name: 'DealListView-Retailer' }
    }
  }

  if (acl.anyRole(['admin', 'rs'])) {
    if (!!to.name && (to.name.includes('-Retailer') || to.name.includes('-Carrier'))) {
      try {
        if (!to.name) {
          throw new Error(`No route name found. Route location is ${to.fullPath}`)
        }

        const name = (to.name as string).split('-')?.[0]

        if (!routeExists(name)) {
          throw new Error('That location was not found')
        }

        return { ...to, name }
      } catch (err) {
        return { name: 'DealListView' }
      }
    }
  }

  const _meta = to.meta as PageMetaType
  if (_meta?.access && ((_meta.access.roles ?? []) as Ability[]).length > 0) {
    if (!acl.anyRole(_meta.access.roles as Ability[])) {
      if (_meta.access.notification) {
        notificationStore.publishByType(_meta.access.notification)
      } else {
        notificationStore.publishErrorMessage('You are not authorized to be there')
      }
      if (_meta.access.route) {
        return _meta.access.route
      } else {
        return '/'
      }
    }
  }

  // Deal Kickoff Step Validation
  if (to.name && ['DealCreateNewLocationStep'].includes(to.name as string) && acl.anyRole(['admin', 'rs'])) {
    if (dealKickoffStore.assetProfile === DealConfigDtoAssetProfileEnum.MultiAsset) {
      return { name: 'DealCreateNewInfoStep' }
    }
  }

  // check if user is a carrier and going to the summary view. If so, redirect them to the detailed view
  // const summaryViews = [
  //   'ProgramLandingComponent',
  //   'ProgramStructure',
  //   'ProgramIndication',
  //   'ProgramScenarios',
  //   'ProgramQuote',
  //   'ProgramBind'
  // ]
  // if (acl.anyRole(['carrier']) && to.name && summaryViews.includes(to.name.toString())) {
  //   const orgId = authStore.userOrganizationId
  //   return { name: 'programMarketDetailed', params: { ...to.params, ...{ marketId: orgId } }, query: { ...to.query, ...{ view: to.name } } }
  // }

  // We need to forward to the redirect url even user doesn't have access. This will allow users to
  // auto provision to the Deal if his org has access to it already.

  // check for program access if specified in the route
  // const ruleName = meta.ruleName as string
  // const redirectTo = (meta.redirectTo as string) || 'DealListView'
  // if (ruleName) {
  //   if (redirectTo == 'ProgramLandingComponent') {
  //     if (acl.notCan(ruleName, [{ id: `${to.params.programId}` }])) {
  //       return {
  //         name: redirectTo,
  //         params: to.params
  //       }
  //     }
  //   } else if (acl.notCan(ruleName, [{ id: `${to.params.dealId}` }])) {
  //     return {
  //       name: redirectTo,
  //       params: to.params
  //     }
  //   }
  // }
  if (meta.loggedInRedirect && authStore.user) {
    const email = (to.query?.email ?? '') as string
    systemEventCaptureService.fireAndForgetProgrammaticEvent({
      ...buildLoginPageViewTemplate(email),
      legacyEventName: 'Login-AlreadyLoggedIn',
      resourceType: USER_SESSION_RESOURCE_TYPE,
      resourceCrudl: CUSTOM_CRUD_DOES_ALREADY_EXIST,
      resourceCrudlResult: 'true',
      resourceInstanceName: email,
      resourceId: authStore.user?.id,
      snapshotUserBlurb: [authStore.user?.id, authStore.user?.email, email].join(', '),
      code: 'login-view-redirect'
    })
    const redirectUrl = authStore.getRedirectUrl()
    authStore.redirectUrl = ''
    return redirectUrl
  }

  function extractUserInfo(response: WhoAmIResponse) {
    const user = response.user!
    const features = response.features || ([] as FeatureDto[])
    const refreshToken = response.refreshToken || ''
    return { user, features, refreshToken }
  }

  function updateStore(user: UserModel, features: FeatureDto[], refreshToken: string) {
    authStore.$patch({
      user,
      features,
      refreshToken
    })
  }

  if (to.name === 'NotFound' && to.fullPath.includes('/retailer/')) {
    return { name: 'DealListView-Retailer' }
  }
  if (to.name === 'NotFound' && to.fullPath.includes('/carrier/')) {
    return { name: 'DealListView-Carrier' }
  }
}

router.beforeEach(routerBeforeEach)

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
    // @ts-ignore
    window.location = to.fullPath
  }
})

export default router
