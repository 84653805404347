<script lang="ts">
import type { AMBestCompanyModel } from '@/capability/ambest/types'

export interface Props {
  modelValue?: AMBestCompanyModel
  search?: string
}

export interface Emits {
  (e: 'update:modelValue', payload: AMBestCompanyModel): void
}
</script>

<script setup lang="ts">
import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'

import { cn } from '@/lib/utils'

import { amBestService } from '@/capability/ambest/service'

import { Command, CommandInput, CommandItem, CommandList } from '@/component/arqu-components/shadcn/ui/command'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '@/component/arqu-components/shadcn/ui/popover'

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  search: ''
})

const emit = defineEmits<Emits>()

const open = ref<boolean>(false)
const search = ref<string>(props.search ?? '')
const items = ref<AMBestCompanyModel[]>([])

function handleSelect(amBestCompanyModel: AMBestCompanyModel) {
  emit('update:modelValue', amBestCompanyModel)
  open.value = false
}

const debouncedFetch = debounce(async (searchTerm: string) => {
  items.value = await amBestService.getAll(searchTerm)
  if (items.value.length == 1) {
    emit('update:modelValue', items.value[0])
  }
}, 500)

watch(
  () => props.search,
  async (value) => {
    if (value) {
      search.value = value
    }
  },
  { immediate: true }
)

watch(
  search,
  async () => {
    if (search.value) {
      debouncedFetch.cancel()
      await debouncedFetch(search.value)
    }
  },
  { immediate: true }
)
</script>

<template>
  <div>
    <Label>Search from the AM Best Companies List</Label>
    <Popover v-model:open="open">
      <PopoverTrigger as-child>
        <rq-btn
          :aria-expanded="open"
          :class="cn('flex h-10 w-full justify-between px-3 text-sm')"
          role="combobox"
          type="button"
          variant="trigger"
        >
          <span v-if="modelValue">{{ modelValue.companyName }}</span>
          <span v-else class="text-gray-500">Select AM Best company</span>
          <rq-icon class="text-gray-500" icon="lucide:chevron-down" />
        </rq-btn>
      </PopoverTrigger>
      <PopoverContent align="start">
        <Command v-model:search-term="search" :model-value="modelValue">
          <CommandInput placeholder="Type to search..." />
          <CommandList>
            <CommandItem v-if="items.length > 100" disabled value="none">Too many results. Please narrow your search</CommandItem>
            <CommandItem v-else-if="!!search && !items.length" disabled value="none">
              Your search for "{{ search }}" returned no results.
            </CommandItem>
            <CommandItem v-for="item in items" :key="item.id as string" :value="item" @select="handleSelect(item)">
              <span>{{ item.companyName }}</span>
              <rq-icon :class="cn('ml-auto', modelValue === item ? 'opacity-100' : 'opacity-0')" icon="lucide:check" />
            </CommandItem>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  </div>
</template>
