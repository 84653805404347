<script lang="ts">
import type { LayerModel } from '@/capability/layer/types'

export interface Props {
  rawSegments: LayerModel[]
  layerId: string
  layerName: string
  layerMax: number
  layerMin: number
  level: number
}

export interface Emits {
  (e: 'selectLayer', payload: boolean): void
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

import type { StyledSegment } from '@/lib/types/tower-svg'
import { useTowerStore } from '@/stores/towers'

import { getNameWithDefault, getStyledLayers } from '@/capability/layer/utils'

import LayerDiagram from '@/component/layer/LayerDiagram.vue'

const props = defineProps<Props>()

const emit = defineEmits<Emits>()

const towerStore = useTowerStore()

const segments = computed((): StyledSegment[] => {
  let left = 0
  return props.rawSegments!.map((segment: LayerModel) => {
    const style = {
      left: `${left}%`,
      width: `${segment.percentage}%`
    }
    left += segment.percentage ?? 0
    return { segment, style }
  })
})

const scrollToSegment = (id: string): void => {
  towerStore.$patch({ activeTowerId: props.layerId, activeLayerId: id })
  emit('selectLayer', false)
  const element = document.getElementById(`segment-group~~${id}`)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const styledLayers = (layers: LayerModel[]) => getStyledLayers(layers, props.layerMax - props.layerMin)
</script>

<template>
  <div
    v-for="{ segment, style } in segments"
    :key="segment.id"
    class="absolute box-border flex h-full p-[1px]"
    :style="style"
    @click.stop="scrollToSegment(segment.id as string)"
  >
    <div id="layers-segment">
      <LayerDiagram
        v-for="layer in styledLayers((segment.layers ?? []) as LayerModel[])"
        :key="layer.layer.id"
        :can-interact="false"
        :layer="layer"
        :level="level + 1"
        :tower-id="layerId"
        :tower-name="layerName"
      />
    </div>
    <div class="z-[999] h-full w-full border border-dashed border-white"></div>
    <div
      v-if="level < 2"
      class="absolute whitespace-nowrap"
      :class="level === 1 ? 'font-base right-[3px] top-0 text-xs italic' : 'bottom-0 left-[3px]'"
    >
      {{ segment.percentage }}%
    </div>
    <rq-tooltip :options="{ placement: 'bottom-start', arrow: false }" :text="`${segment.percentage}%: ${getNameWithDefault(segment)}`" />
  </div>
</template>

<style scoped lang="scss"></style>
