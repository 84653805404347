<script lang="ts">
import type { ProgramModel } from '@/capability/program/types'

export type Props = {
  program: ProgramModel
  fileName?: string
  dialog?: boolean
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { ref, watch } from 'vue'
import { Buffer } from 'buffer'
import fileDownload from 'js-file-download'

import { useNotificationStore } from '@/stores/notification'

import { indicationService } from '@/capability/indication/service'
import type { IndicationMarketModel } from '@/capability/indication/types'
import { CreateQuoteLetterRequestExportFormatEnum } from 'typescript-core-api-client/dist/api'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { RadioGroup, RadioGroupItem } from '@/component/arqu-components/shadcn/ui/radio-group'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import ProgramIndicationIssuingCompanyDialog from '@/component/program/program-indications/program-indications-menu/ProgramIndicationIssuingCompanyDialog.vue'

const props = withDefaults(defineProps<Props>(), {
  fileName: 'QuoteLetter',
  dialog: false
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const notificationStore = useNotificationStore()

const markets = ref<IndicationMarketModel[]>([])

const loading = ref<boolean>(false)
const selectedTower = ref<string>('')
const selectedMarketIds = ref<string[]>([])
const selectedFileFormat = ref<CreateQuoteLetterRequestExportFormatEnum>(CreateQuoteLetterRequestExportFormatEnum.Pdf)
const open = ref<boolean>(props.dialog)

async function handleGenerateQuote() {
  try {
    loading.value = true
    const content = await indicationService.generateQuoteLetter({
      dealId: props.program.dealId!,
      programId: props.program.id!,
      towerId: selectedTower.value,
      marketIds: selectedMarketIds.value,
      exportFormat: selectedFileFormat.value as CreateQuoteLetterRequestExportFormatEnum
    })
    const buf = Buffer.from(content, 'base64')
    fileDownload(buf, `${props.fileName}.${getFileExtension()}`)
    notificationStore.publishSuccessMessage('Quote Letter generated successfully')
  } catch (e: any) {
    notificationStore.publishOneOrMoreErrUnhandled(e.message)
  } finally {
    loading.value = false
    open.value = false
    resetDialog()
  }
}

function getFileExtension() {
  return selectedFileFormat.value == CreateQuoteLetterRequestExportFormatEnum.Excel ? 'xlsx' : 'pdf'
}

function resetDialog() {
  selectedTower.value = ''
  selectedMarketIds.value = []
  selectedFileFormat.value = CreateQuoteLetterRequestExportFormatEnum.Pdf
}

const marketWithNoIssuingCompany = computed(() =>
  markets.value.filter((market) => selectedMarketIds.value.includes(market.marketId!) && !market.issuingCompanyName)
)

watch(
  open,
  async (isOpen) => {
    if (!isOpen) {
      resetDialog()
      emit('close')
    } else {
      const marketPairsModel = await indicationService.getIndicationMarketsPairsByProgramIds({ programIds: [props.program.id!] })
      markets.value = marketPairsModel?.indicationMarkets?.filter((market) => market.createdByUserType == 'Carrier') || []
    }
  },
  { immediate: true }
)

async function handleClosed() {
  const marketPairsModel = await indicationService.getIndicationMarketsPairsByProgramIds({ programIds: [props.program.id!] })
  markets.value = marketPairsModel?.indicationMarkets?.filter((market) => market.createdByUserType == 'Carrier') || []
}
</script>

<template>
  <TooltipProvider :delay-duration="100">
    <Tooltip>
      <TooltipTrigger>
        <Dialog v-model:open="open">
          <DialogTrigger as-child>
            <rq-btn id="programs-navbar-quote-letter-button" icon="square" size="lg" variant="ghost-primary">
              <rq-icon icon="lucide:mail" />
            </rq-btn>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Generate a Quote Letter</DialogTitle>
              <DialogDescription>Select a Tower and Markets</DialogDescription>
            </DialogHeader>
            <div class="space-y-2">
              <rq-listbox-single
                v-model="selectedTower"
                id="tower-select"
                :items="program.towers"
                label="Select Tower"
                text-field="name"
                truncate-length="48"
                value-field="id"
              />
              <rq-combobox-multi
                v-model="selectedMarketIds"
                :items="markets"
                label="Select Markets"
                multiple
                text-field="marketName"
                truncate-length="48"
                value-field="marketId"
              />
              <ProgramIndicationIssuingCompanyDialog
                v-for="market in marketWithNoIssuingCompany"
                :key="market.marketId"
                location="dialog"
                :market="market"
                @close="handleClosed"
              />
              <div class="space-y-1">
                <Label>Select File Format</Label>
                <RadioGroup v-model="selectedFileFormat" class="flex items-center space-x-2" orientation="horizontal">
                  <div class="flex items-center space-x-2">
                    <RadioGroupItem id="pdf" value="PDF" />
                    <Label for="pdf">PDF</Label>
                  </div>
                  <div class="flex items-center space-x-2">
                    <RadioGroupItem id="excel" value="Excel" />
                    <Label for="xlsx">Excel</Label>
                  </div>
                </RadioGroup>
              </div>
              <DialogFooter class="pt-4">
                <rq-btn :loading="loading" variant="primary" @click="handleGenerateQuote">Generate Quote Letter</rq-btn>
              </DialogFooter>
            </div>
          </DialogContent>
        </Dialog>
      </TooltipTrigger>
      <TooltipContent>Generate Quote Letter</TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>

<style scoped></style>
