<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, inject, ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { DialogConfirm } from '@/composables/dialog/dialogConfirm'
import { currencyFormatter, removeCommasFromStringNumber } from '@/lib/utils/formatting/number'
import { useProgramStore } from '@/stores/program'

import type { LayerModel } from '@/capability/layer/types'
import { fillInLayerGaps, type FillInLayerGapType, getLayerExcess, getLayerLimit } from '@/capability/layer/utils'
import type { TowerModel } from '@/capability/tower/types'
import {
  getLayersTotal,
  getTopMostLayer,
  getTowerCoverageLimit,
  getTowerExcess,
  getTowerLimit,
  setTowerLimit
} from '@/capability/tower/utils'

import LayerModifyStructure from '@/component/layer/LayerModifyStructure.vue'

const props = defineProps({
  tower: {
    type: Object as PropType<TowerModel>,
    required: true
  },
  toBeUpdated: {
    type: Boolean,
    required: true
  },
  isNewTower: {
    type: Boolean,
    required: true
  },
  errors: {
    type: Object as PropType<ErrorType>,
    required: false,
    default: () => ({}) as ErrorType
  }
})

const emit = defineEmits<{
  (e: 'update:tower', tower: TowerModel, removeNewFlag?: Boolean, toBeUpdated?: Boolean): void
  (e: 'remove:tower', id: string): void
  (e: 'keydown:submit'): void
}>()

type ErrorType = {
  name: string[]
  limit: string[]
  excess: string[]
}

const programStore = useProgramStore()

const placeholder = computed(() => {
  let text: string
  const product = programStore.products.find((p) => p.name === programStore.program?.product)
  if (!product) {
    text = 'My New Tower'
  } else {
    text = product.displayName ?? 'My New Tower'
  }
  return `Ex: ${text}`
})

const layerTotal = ref<number>(getLayersTotal(props.tower!.layers ?? []))

const limitsNotEqual = computed(() => layerTotal.value !== getTowerCoverageLimit(props.tower!))

const showButtons = computed(() => (props.isNewTower || isInvalidTower.value ? false : props.toBeUpdated))

const name = computed({
  get(): string {
    return props.tower!.name as string
  },
  set(newValue: string): void {
    const tower = cloneDeep(props.tower)
    tower.name = newValue
    emit('update:tower', tower)
  }
})

const excess = computed(() => getTowerExcess(props.tower!) ?? 0)

const limit = computed({
  get(): string {
    return (getTowerLimit(props.tower!) ?? '').toLocaleString()
  },
  set(newValue: string) {
    const tower = cloneDeep(props.tower)
    const limit = removeCommasFromStringNumber(newValue)!
    const updateFlag = limit !== highestLayerValue.value
    setTowerLimit(tower, limit)
    emit('update:tower', tower, undefined, updateFlag)
  }
})

function handleUpdateLayers(layers: LayerModel[]): void {
  const tower = cloneDeep(props.tower)
  tower.layers = layers
  layerTotal.value = getLayersTotal(layers)
  emit('update:tower', tower, true, false)
}

const isInvalidTower = computed(() =>
  props
    .tower!.layers?.filter((e) => !e.plug)
    .some((l) => (getLayerExcess(l) ?? 0) + (getLayerLimit(l) ?? 0) > getTowerCoverageLimit(props.tower!))
)

const highestLayerValue = computed(() => {
  const layer = getTopMostLayer(props.tower!.layers ?? [])
  if (!layer) return 0
  return (getLayerLimit(layer) ?? 0) + (getLayerExcess(layer) ?? 0)
})

function handleAutoUpdate() {
  const plugLayers = props.tower!.layers?.filter((e) => e.plug) ?? ([] as LayerModel[])
  const nonPlugLayers = props.tower!.layers?.filter((e) => !e.plug) ?? ([] as LayerModel[])
  const towerMax = getTowerCoverageLimit(props.tower!)
  const { filledLayers } = fillInLayerGaps({ nonPlugLayers, plugLayers, towerMax } as FillInLayerGapType)
  handleUpdateLayers(
    filledLayers.map((e) => {
      const layer = cloneDeep(e)
      layer.plug = false
      return layer
    })
  )
}

const confirm = inject(DialogConfirm)!

async function handleRemove() {
  await confirm({
    icon: 'mdi:trash-can-outline',
    title: 'Delete Tower',
    description: `Are you sure you want to delete "${
      props.tower!.name
    }"? All the specs and assigned markets related to this tower will be deleted as well.`,
    okText: 'Yes, delete it',
    cancelText: "No, don't delete it",
    onOk: async () => {
      await emit('remove:tower', props.tower!.id as string)
    }
  })
}
</script>

<template>
  <div class="border-b py-2">
    <div class="flex items-end">
      <rq-text-field
        v-model="name"
        :id="`tower-input-${tower.id}-name`"
        datacy="towerInput-name"
        :errors="errors.name"
        :placeholder="placeholder"
        type="text"
        wrapper-class="grow"
      >
        <template #label>
          <div class="space-x-2">
            <span>Tower Name</span>
            <span class="italic text-gray-400">(Coverage / Line of Business)</span>
          </div>
        </template>
      </rq-text-field>
      <div class="flex items-center">
        <rq-tooltip-btn content="Remove Tower" icon="square" size="lg" type="button" variant="ghost" @click="handleRemove">
          <rq-icon icon="lucide:circle-minus" />
        </rq-tooltip-btn>
        <LayerModifyStructure
          :in-schematic-diagram="false"
          :layers="tower.layers"
          :names="[tower.name as string]"
          :tower-id="tower.id as string"
          :tower-max="getTowerCoverageLimit(tower)"
          :tower-min="0"
          trigger-tooltip
          @update:layers="handleUpdateLayers"
        />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-3">
      <rq-text-field
        :id="`tower-input-${tower.id}-excess`"
        details-size="small"
        :errors="errors.excess"
        hint="Read-Only"
        input-class="pl-7"
        label="Start"
        :model-value="excess.toString()"
        prepend-class="pl-1"
        prepend-icon="mdi:currency-usd"
        readonly
        type="text"
        wrapper-class="col-span-5"
      />
      <rq-text-field
        v-model="limit"
        :id="`tower-input-${tower.id}-limit`"
        datacy="towerInput-limit"
        details-size="small"
        :errors="errors.limit"
        :hint="`Current Layer Total: ${currencyFormatter().format(layerTotal)}`"
        input-class="pl-7"
        label="Limit"
        :persistent-hint="limitsNotEqual"
        prepend-class="pl-1"
        prepend-icon="mdi:currency-usd"
        type="text"
        wrapper-class="col-span-7"
      />
      <div v-if="showButtons" class="col-span-5">
        <rq-btn class="w-full" variant="primary" @click="handleAutoUpdate">Fill gap with layer</rq-btn>
      </div>
      <div v-if="showButtons" class="col-span-7">
        <LayerModifyStructure
          v-if="showButtons"
          :in-schematic-diagram="false"
          :in-tower-input-row="false"
          :layers="tower.layers"
          :names="[tower.name as string]"
          :tower-id="tower.id as string"
          :tower-max="getTowerCoverageLimit(tower)"
          :tower-min="0"
          @update:layers="handleUpdateLayers"
        />
      </div>
      <div v-if="isInvalidTower" class="col-span-12 mt-2 grid grid-cols-12 items-center rounded-xl border border-red-500 p-2">
        <rq-icon class="col-span-1" color="red" icon="heroicons:exclamation-circle-solid" />
        <span class="col-span-11 text-center text-sm text-red-500">
          The top-most layer of this tower is {{ currencyFormatter().format(highestLayerValue) }} while the tower limit is only
          {{ currencyFormatter().format(getTowerCoverageLimit(tower)) }}. Please either update the tower limit or update the layers before
          submitting.
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
