// todo (JVP): replace with JS-based implementations https://arqu-co.atlassian.net/browse/RQ-5448

import type { PrivilegeDto } from 'typescript-core-api-client'

export type PrivilegeModel = PrivilegeDto

export interface Privilege {
  getPrefix(): string

  getName(): string

  createName(entityId: string): string
}

export class DealPrivilege implements Privilege {
  private static readonly instances: DealPrivilege[] = []

  static readonly Read = new DealPrivilege('deal-read', 'READ')
  static readonly Write = new DealPrivilege('deal-write', 'WRITE')
  static readonly ReadInfo = new DealPrivilege('deal-read-info', 'READ_INFO')
  static readonly ReadRiskAttributes = new DealPrivilege('deal-read-risk-attributes', 'READ_RISK_ATTRIBUTES')
  static readonly ReadDocuments = new DealPrivilege('deal-read-documents', 'READ_DOCUMENTS')
  static readonly ReadMessages = new DealPrivilege('deal-read-messages', 'READ_MESSAGES')
  static readonly ReadProgress = new DealPrivilege('deal-read-progress', 'READ_PROGRESS')
  static readonly ReadActivity = new DealPrivilege('deal-read-activity', 'READ_ACTIVITY')

  readonly ruleName: string
  readonly privilegeName: string

  private constructor(ruleName: string, privilegeName: string) {
    this.ruleName = ruleName
    this.privilegeName = privilegeName
    DealPrivilege.instances.push(this)
  }

  public getPrefix(): string {
    return 'DEAL'
  }

  public getName(): string {
    return this.privilegeName
  }

  public createName(entityId: string): string {
    return `${this.getPrefix()}_${this.getName()}_${entityId}`
  }

  public static getAll(): DealPrivilege[] {
    return DealPrivilege.instances
  }
}

export class ProgramPrivilege implements Privilege {
  private static readonly instances: ProgramPrivilege[] = []

  static readonly Read = new ProgramPrivilege('program-read', 'READ')
  static readonly Write = new ProgramPrivilege('program-write', 'WRITE')
  static readonly Structure = new ProgramPrivilege('program-read-structure', 'READ_STRUCTURE')
  static readonly Indication = new ProgramPrivilege('program-read-indication', 'READ_INDICATION')
  static readonly Scenarios = new ProgramPrivilege('program-read-scenarios', 'READ_SCENARIO')
  static readonly BindAuth = new ProgramPrivilege('program-read-bind-auth', 'READ_BINDAUTH')
  static readonly BindOrder = new ProgramPrivilege('program-read-bind-order', 'READ_BINDORDER')

  readonly ruleName: string
  readonly privilegeName: string

  private constructor(ruleName: string, privilegeName: string) {
    this.ruleName = ruleName
    this.privilegeName = privilegeName
    ProgramPrivilege.instances.push(this)
  }

  public getPrefix(): string {
    return 'PROGRAM'
  }

  public getName(): string {
    return this.privilegeName
  }

  public static getAll(): ProgramPrivilege[] {
    return ProgramPrivilege.instances
  }

  public createName(entityId: string): string {
    return `${this.getPrefix()}_${this.getName()}_${entityId}`
  }
}

export class MessagePrivilege implements Privilege {
  private static readonly instances: MessagePrivilege[] = []

  static readonly Read = new MessagePrivilege('message-read', 'READ')

  readonly ruleName: string
  readonly privilegeName: string

  private constructor(ruleName: string, privilegeName: string) {
    this.ruleName = ruleName
    this.privilegeName = privilegeName
    MessagePrivilege.instances.push(this)
  }

  public getPrefix(): string {
    return 'MESSAGE'
  }

  public getName(): string {
    return this.privilegeName
  }

  public static getAll(): MessagePrivilege[] {
    return MessagePrivilege.instances
  }

  public createName(entityId: string): string {
    return `${this.getPrefix()}_${this.getName()}_${entityId}`
  }
}

export class DocumentPrivilege implements Privilege {
  private static readonly instances: DocumentPrivilege[] = []

  static readonly Read = new DocumentPrivilege('document-read', 'READ')
  static readonly Write = new DocumentPrivilege('document-write', 'WRITE')

  readonly ruleName: string
  readonly privilegeName: string

  private constructor(ruleName: string, privilegeName: string) {
    this.ruleName = ruleName
    this.privilegeName = privilegeName
    DocumentPrivilege.instances.push(this)
  }

  public getPrefix(): string {
    return 'DOCUMENT'
  }

  public getName(): string {
    return this.privilegeName
  }

  public createName(entityId: string): string {
    return `${this.getPrefix()}_${this.getName()}_${entityId}`
  }

  public static getAll(): DocumentPrivilege[] {
    return DocumentPrivilege.instances
  }
}
