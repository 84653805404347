import cloneDeep from 'lodash.clonedeep'

import { getLogger } from '@/composables/util/log/logger'
import { isPresent, undefineIfEmpty } from '@/lib/utils/formatting'
import { generateUuid } from '@/lib/utils/id'
import { isCarrier, isRiskSpecialist } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'

import { PAGE_VIEW_CODE, PAGE_VIEW_WORKER_ACTION, PROGRAMMATIC_CODE, USER_ACTIVITY_CODE } from '@/capability/event/utils'

import type { SystemEvent } from './types'

export interface SystemEventCaptureService {
  fireAndForgetSystemEvent(systemEvent: SystemEvent): void
  fireAndForgetPageView(systemEvent: SystemEvent): void
  fireAndForgetUserActivity(systemEvent: SystemEvent): void
  fireAndForgetProgrammaticEvent(systemEvent: SystemEvent): void
}

export const systemEventCaptureService = {
  fireAndForgetSystemEvent: function (systemEvent: SystemEvent): void {
    const logger = getLogger('SystemEventCaptureServiceImpl')
    if (import.meta.env.VITE_SYSTEM_EVENT_CAPTURE_SERVICE_ENABLED_FLAG !== 'true') {
      logger.info(`SystemEventCaptureService is DISABLED per config.  Will not capture event`, {
        context: { method: 'fireAndForgetSystemEvent' }
      })
      return
    }

    const workingSystemEvent = cloneDeep(systemEvent)

    workingSystemEvent.id = generateUuid()

    workingSystemEvent.eventSchemaVersion = 'v1'

    const authStore = useAuthStore()
    if (authStore.user != null) {
      workingSystemEvent.signedInUserId = authStore.user.id
      workingSystemEvent.signedInOrganizationId = authStore.user.organizationId
      workingSystemEvent.snapshotUserBlurb = undefineIfEmpty(
        [authStore.user.email, authStore.user.firstName, authStore.user.lastName].filter((x) => isPresent(x)).join(', ')
      )

      // I think this is all we know about the organization?
      workingSystemEvent.snapshotOrganizationBlurb = authStore.user.organizationId

      workingSystemEvent.carrierFlag = isCarrier(authStore.user)

      if (isRiskSpecialist(authStore.user)) {
        workingSystemEvent.riskSpecialistUserId = workingSystemEvent.signedInUserId
      }

      workingSystemEvent.signedInOrganizationId = authStore.user.organizationId
    }
    workingSystemEvent.authToken = authStore.accessToken
    workingSystemEvent.appAgent = `arqu-web; ${[import.meta.env.VITE_GIT_BRANCH, undefineIfEmpty(import.meta.env.VITE_GIT_COMMIT_HASH)]
      .filter((x) => isPresent(x))
      .join('--')}` // whatever my version of arqu-web is
    workingSystemEvent.appName = 'arqu-web'
    workingSystemEvent.appVersion = undefineIfEmpty(
      `${[import.meta.env.VITE_GIT_BRANCH, undefineIfEmpty(import.meta.env.VITE_GIT_COMMIT_HASH)].filter((x) => isPresent(x)).join('--')}`
    )
    workingSystemEvent.referrer = window.location.href
    workingSystemEvent.screenWidth = screen.width
    workingSystemEvent.screenHeight = screen.height

    workingSystemEvent.resourceInstanceName = undefineIfEmpty(workingSystemEvent.resourceInstanceName)
    workingSystemEvent.snapshotUserBlurb = undefineIfEmpty(workingSystemEvent.snapshotUserBlurb)
    workingSystemEvent.authToken = undefineIfEmpty(workingSystemEvent.authToken)
    workingSystemEvent.appVersion = undefineIfEmpty(workingSystemEvent.appVersion)

    const endpoint = import.meta.env.VITE_EVENT_REPORTING_ENDPOINT
    const headers = new Headers({ 'Content-Type': 'application/json; charset=UTF-8' })
    let sessionId = sessionStorage.getItem(`rq-session-${import.meta.env.VITE_ARQU_ENVIRONMENT}`)
    if (!sessionId) {
      sessionId = generateUuid()
      sessionStorage.setItem(`rq-session-${import.meta.env.VITE_ARQU_ENVIRONMENT}`, sessionId)
    }
    headers.append('X-Arqu-Session-Id', sessionId)

    setTimeout(async () => {
      try {
        await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(workingSystemEvent),
          headers
        })
      } catch (err) {
        logger.warn(err, {
          context: {
            method: 'fireAndForgetSystemEvent'
          }
        })
      }
    })
  },
  fireAndForgetPageView: function (systemEvent: SystemEvent): void {
    const workingSystemEvent = cloneDeep(systemEvent)
    workingSystemEvent.workerAction = PAGE_VIEW_WORKER_ACTION
    workingSystemEvent.code = PAGE_VIEW_CODE

    /* This was moved to eventproc */

    // if ( workingSystemEvent.code == null || !workingSystemEvent.code.startsWith(PAGE_VIEW_CODE)) {
    //   workingSystemEvent.code = `${[PAGE_VIEW_CODE, workingSystemEvent.code].filter(x => isPresent(x)).join("-")}`
    // }
    this.fireAndForgetSystemEvent(workingSystemEvent)
  },
  fireAndForgetUserActivity: function (systemEvent: SystemEvent): void {
    const workingSystemEvent = cloneDeep(systemEvent)
    workingSystemEvent.code = USER_ACTIVITY_CODE
    this.fireAndForgetSystemEvent(workingSystemEvent)
  },
  fireAndForgetProgrammaticEvent: function (systemEvent: SystemEvent): void {
    const workingSystemEvent = cloneDeep(systemEvent)
    workingSystemEvent.code = PROGRAMMATIC_CODE
    this.fireAndForgetSystemEvent(workingSystemEvent)
  }
} satisfies SystemEventCaptureService
