import type { RouteRecordRaw } from 'vue-router'

import { ProgramView } from '@/capability/program/types'

const routes: RouteRecordRaw = {
  path: 'new',
  component: () => import('@/views/program/v2/ProgramWrapperComponent.vue'),
  name: 'NewProgramUxWrapper',
  meta: {
    viewName: ProgramView.UNKNOWN,
    pageViewDetails: {
      // pageId: 'program-overview',
      // resourceType: 'program',
      // resourceCrudl: 'overview'
    },
    requiresAuth: true,
    title: 'New Program UX Wrapper',
    isNewProgramRoute: true
  },
  children: [
    {
      path: ':programId',
      component: () => import('@/views/program/v2/ProgramBaseComponent.vue'),
      name: 'New Base Program Component',
      meta: {
        requiresAuth: true,
        title: 'New Program Page',
        isNewProgramRoute: true
      }
    }
  ]
}

export default routes
